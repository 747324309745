import React, { useEffect, useRef, useState } from "react";
import FriedChicken from "../../../img/Icon/FriedChicken.svg";
import KadaiGosh from "../../../img/Icon/KadaiGosh.svg";
import Rice from "../../../img/Icon/Rice.svg";
import ButterRoti from "../../../img/Icon/ButterRoti.png";
import Mango from "../../../img/Icon/Mango.svg";
import Kheericon from "../../../img/Icon/Kheer_icon.png";
import user_icon from "../../../img/Icon/user_icon.svg";
import ven from "../../../img/ven.png";
import { Link, useNavigate } from "react-router-dom";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro";
import DateRangePicker from "react-bootstrap-daterangepicker";
import calender from "../../../img/Icon/calender.svg";
import loadergif from "../../../img/landing/loader123.gif";
import email_icon from "../../../img/Icon/email.svg";
import phone_icon from "../../../img/Icon/phone.svg";
import remove from "../../../img/remove.png";
import dishlist from "../../../img/dishlist.png";
import { CSVLink } from 'react-csv';

import DatePicker from "react-datepicker";
import {
  Thalischedule,
  getThaliScheduleview,
  fmb_food_items_ingredients,
  getTotalIngredients,
  api_url,
  getlabelView,
  getzoneView,
  getEventView,
  menu_schedule,
  feed_back,
  fmb_schedule_url,
  getThaliview,
  getDishTypeView,
  getDishName,
  Updatethali,
  getThalilist,
  getDishNameView,
  getDishType,
  getAllcaterer,
  dishtypelistall,
  deletethali
} from "../../../Redux/Reducer/AuthReducer";
import Select from "react-select";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import soup from "../../../img/Icon/soup.svg";
import Drinks from "../../../img/Icon/Drinks.svg";
import evnetlog from "../../../img/fmb-logo.png";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import Modal from "react-modal";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { orange, yellow } from "@mui/material/colors";
import { isMobile } from "../../Helper/Helper";

export default function ThaliSchedule() {
  const modalCloseBtnRef = useRef();

  var token = localStorage.getItem("access_token");
  const user_Role = localStorage.getItem("access_Role");

  const customModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const access_id = localStorage.getItem("access_id");

  const printRef = useRef();
  const printlabel = useRef();
  const today = new Date();
  const CurrentendOfWeek = new Date(today);
  CurrentendOfWeek.setDate(today.getDate() + (6 - today.getDay()));
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay());

  const [Thalischeduledata, setThaliScheduleData] = useState([]);
  const [Thalicount, setThalicount] = useState("");
  const [startDate, setStartDate] = useState(startOfWeek);
  const [endDate, setEndDate] = useState(CurrentendOfWeek);
  const [startDateall, setStartDateAll] = useState(new Date());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [Labeldata, setLabelData] = useState("");
  const [zonedata, setZoneData] = useState([]);
  const [tabelhide, setTabelHide] = useState(false);
  const [ingredientpdf, setingredientpdf] = useState("");
  const [ingredientMenuSummarrypdf, setingredientMenuSummarypdf] = useState([]);
  const [fatehaNamePDF, setFatehaNamePdf] = useState([]);
  const [ingredient100MenuSummarrypdf, setingredient100MenuSummarypdf] = useState([]);
  const [is_niyaaz, setIsNiyaaz] = useState("0");
  const [ingrediencount, setIngredientCount] = useState("");
  const [scheduleType, setScheduleType] = useState("");
  const [scheduleDay, setScheduleDay] = useState("");
  
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#AE8340" : null,
        boxShadow: isFocused ? "none" : "none",
        color: "#333333",
      };
    },
  };

  const handleNavigateDishPage = (dish_name, schedule_type) => {
    setTimeout(() => {
      navigate('/Dishes', { state: { schedule_type: schedule_type, dish_name: dish_name} });
    }, 1000);
  };

  function convertDateForIos(date) {
    var arr = date.split(/[- :]/);
    date = new Date(arr[2], arr[0] - 1, arr[1]);
    return date;
  }

  function round(value, precision) {
    // var multiplier = Math.pow(10, precision || 0);
    // return Math.round(value * multiplier) / multiplier == 0 ? 1 : Math.round(value * multiplier) / multiplier;
    return value;
    // return Math.ceil(value)
}

  const getFatehaName = (dishname, name, count, schedule_day) => {
    if (name) {
      setFatehaNamePdf(name);
      setIngredientCount(count);
      FatehaLabelTypeName(schedule_day)
    }
  };

  const [ingredientcountbyportionpdf, setingredientCountbyPortionpdf] =
    useState([]);

  const [loading, setLoading] = useState(true);
  const [EventType, setEventType] = useState([]);
  const [RemoveIsOpen, setIsRemoveOpen] = React.useState(false);

  const [menudata, setMenuData] = useState("");



  const csvData = Thalischeduledata.map((items) => {

    return {
        Date: items.schedule_day,
        Menu: JSON.parse(items.menu),
        Type: items.schedule_type,
        Miqaat: items.is_niyaaz,
        Notes: items.notes,
        CatererName: items.cater_name1,
        CatererEmail: items.cater1_email,
        CatererPhoneNumber: items.cater1_phone_number,

    };
});

const csvHeaders = [
    { label: "Date", key: "Date" },
    { label: "Menu", key: "Menu" },
    { label: "Type", key: "Type"},
    { label: "Miqaat", key : "Miqaat"},
    { label: "Notes", key: "Notes"},
    { label: "Caterer Name", key: "CatererName" },
    { label: "Caterer Email", key: "CatererEmail" },
    { label: "Caterer Phone Number", key: "CatererPhoneNumber" },
];



  // const IngredientDays = Ingredients?.schedule.map(item => item?.ingredient_schedule_day)
  // const itemsPerPage = Thalischeduledata?.length; // Number of items to display per page

  // const pageCount = Math.ceil(Thalischeduledata?.length / itemsPerPage);

  // const handlePageChange = (pageNumber) => {
  //   console.log ("handlePageChange clicked")

  //   if (pageNumber < 1) {
  //     setCurrentPage(1);
  //   } else if (pageNumber > pageCount) {
  //     setCurrentPage(pageCount);
  //   } else {
  //     setCurrentPage(pageNumber);
  //   }
  // };

  function convertDateForIos(date) {
    var arr = date?.split(/[- :]/);
    date = new Date(arr[2], arr[0]-1, arr[1]);
    return date;
}

  const handlePreviousPage = () => {
    console.log ("handlePreviousPage clicked")
    const currentStartDate = convertDateForIos(Thalischeduledata[0]?.schedule_day)

    if(!currentStartDate){
      return
    }

    const today = new Date(currentStartDate);
    today.setHours(0,0,0,0)
    const prevWeekStart = new Date(today);
    const prevWeekEnd = new Date(today);

    prevWeekStart.setDate(today.getDate() - 7);
    prevWeekEnd.setDate(today.getDate()-1);

    const requestBody = {
      start_date: prevWeekStart.toISOString().split("T")[0],
      end_date: prevWeekEnd.toISOString().split("T")[0],
    };
    getdatechange(requestBody);
  };

  const handleCurrentPage = () => {
    console.log ("handleCurrentPage clicked")
    Thalischedulelist();
  };

  const handleNextPage = () => {
    console.log ("handleNextPage clicked")
    const currentStartDate = convertDateForIos(Thalischeduledata[0]?.schedule_day)

    if(!currentStartDate){
      return
    }

    const today = new Date(currentStartDate);
    today.setHours(0,0,0,0)
    const prevWeekStart = new Date(today);
    const prevWeekEnd = new Date(today);

    prevWeekStart.setDate(today.getDate() + 7);
    prevWeekEnd.setDate(today.getDate() + 14);


    const requestBody = {
      start_date: prevWeekStart.toISOString().split("T")[0],
      end_date: prevWeekEnd.toISOString().split("T")[0],
    };
    getdatechange(requestBody);
  };

  // const formattedStartDate = startDate.toISOString().split('T')[0];

  const Thalischedulelist = () => {
    setLoading(true);
    dispatch(Thalischedule())
      .then((res) => {
        if (res?.payload?.status == true) {
          setThaliScheduleData(res?.payload?.data);
        } else {
          toast.error(res?.payload?.message);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };

  const getEventViewType = (id) => {
    dispatch(getEventView(id)).then((res) => {
      if (res.payload.status === true) {
        // toast.success(res.payload.message);

        setIsRemoveOpen(true);
        console.log("res?.payload?.datasss", res.payload.status);
        setEventType(res?.payload?.payload);

        // setDishNameListData(res?.payload?.data);
        // setDishId()
      } else {
        toast.error(res.payload.message);
        console.log("res?.payload?.data", res.payload.message);
        setIsRemoveOpen(false);
      }
    });
  };

  const getdatechange = (requestBody) => {
    fetch(fmb_schedule_url + "admin/thaali-list-date", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          setThaliScheduleData(data?.data);
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRsvpDateChange = (date) => {
    const formattedDate = moment(date).format('MM/DD/YYYY');
    setAllDateList(formattedDate);
    console.log('formattedDate', formattedDate)
  };

  const handleDateChange = (event) => {
    const selectedDates = event.target.value.split(" - ");
    const selectedStartDate = selectedDates[0];
    const selectedEndDate = selectedDates[1];
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
    const parts = selectedEndDate.split("/");
    const formattedEndDate = `${parts[2]}-${parts[0].padStart(
      2,
      "0"
    )}-${parts[1].padStart(2, "0")}`;
    const partsstart = selectedStartDate.split("/");
    const formattedStartDate = `${partsstart[2]}-${partsstart[0].padStart(
      2,
      "0"
    )}-${partsstart[1].padStart(2, "0")}`;
    console.log(
      "Selected Dates:",
      "startDate =",
      selectedEndDate,
      "endDate =",
      formattedStartDate
    );
    const requestBody = {
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    };
    getdatechange(requestBody);
  };

  // const getthalicountId = (scheduleDay) => {
  //   dispatch(getThaliScheduleview(scheduleDay)).then((res) => {
  //     if (res.payload.status === true) {
  //       const thalicountData = res.payload.data;
  //       // Pass the thalicountData to the ThaliPage route using navigate
  //       navigate('/Registeruser', { state: { thalicount: thalicountData } });
  //     } else {
  //       toast.error(res.payload.message);
  //     }
  //   });
  // };

  const getthalicountId = (schedule_day) => {
    setLoading(true);
    setStartDate(schedule_day);

    const parts = schedule_day.split("-"); // Split the date string into parts
    const month = parseInt(parts[0]) - 1; // Extract month (months are zero-indexed)
    const day = parseInt(parts[1]); // Extract day
    const year = parseInt(parts[2]); // Extract year

    const formattedDate = `${year}-${(month + 1)
      .toString()
      .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
    const formattedStartDate = formattedDate;

    const requestBody = {
      date: formattedStartDate,
    };

    fetch(feed_back + "admin/date-register-user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          console.log("new data ", data.data);
          const thalicountData = data?.data;
          const thaliallcount = data?.count;
          const scheduleId = data?.menu_summary[0]?.schedule_id;
          const menuSummary = data?.menu_summary;
          const thalialldate = formattedStartDate;
          navigate("/Registeruser", {
            state: {
              thalicount: thalicountData,
              allcount: thaliallcount,
              Todaydate: thalialldate,
              scheduleId: scheduleId,
              menuSummary: menuSummary,
            },
          });
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };

  const getIngredientsId = (
    ingredient_id_array,
    id,
    schedule_day,
    schedule_type,
    is_niyaaz,
    ingredientCount
  ) => {
    const requestBody = {
      ingredient_id: [ingredient_id_array],
      schedule_id: id,
    };

    let url = schedule_type === "FMBTHALI" ? "ingredients-get-by-id" : "ingredients-get-by-id-miqat"

    if (schedule_type == "FMBTHALI" && is_niyaaz == '1'){
      url = "ingredients-get-by-id-fmb-niyaz"
    }

    fetch(fmb_food_items_ingredients + "admin/" + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          const ingredientscount = data.data;
          const scheduleday = schedule_day;
          const ingredientCountall = ingredientCount;
          setScheduleType (schedule_type);
          setIsNiyaaz(is_niyaaz);
          setScheduleDay (scheduleday);
          navigate("/Total-Ingredients", {
            state: {
              ingredientscountlist: ingredientscount,
              scheduledayweek: scheduleday,
              ingredientCountdata: ingredientCountall,
              scheduletype: schedule_type
            },
          });
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const zoneDataFileName =  "Zone_Sheets_"+scheduleDay+".pdf"
  const labelDataFileName = "Labels_"+scheduleDay+".pdf"
  const miqaatLabelDataFileName = "Miqaat_Labels_"+scheduleDay+".pdf"
  const fatehaDataFileName = "Fateha_Labels_"+scheduleDay+".pdf"
  const ingredientsFileName = scheduleType === "FMBTHALI" ? "FMB_Ingredients_Sheet_"+scheduleDay+".pdf": scheduleType === "MIQAAT" && is_niyaaz != '1' ? "MIQAAT_Ingredients_Sheet_"+scheduleDay+".pdf" : "NIYAAZ_Ingredients_Sheet_"+scheduleDay+".pdf"
  const ingredients100FileName = scheduleType === "FMBTHALI" ? "FMB_Ingredients_100_Sheet_"+scheduleDay+".pdf": scheduleType === "MIQAAT" && is_niyaaz != '1' ? "MIQAAT_Ingredients_10_Sheet_"+scheduleDay+".pdf" : "NIYAAZ_Ingredients_10_Sheet_"+scheduleDay+".pdf" 

  const FatehaLabelTypeName = (schedule_day) => {
    setLoading (true);
    setScheduleDay(schedule_day)
    dispatch(getlabelView(schedule_day)).then((res) => {
      if (res?.payload === res?.payload) {
        console.log("res", res?.payload?.data);
        setLabelData(res?.payload?.data);

        if (res?.payload?.data.length > 0) {
          toast.success("wait Label Sheet Download");
          setTimeout(() => {
            AutoclickFateha();
            setLoading (false);
          }, 1000);
        } else {
          toast.error("No Label Sheet Data");
          setLoading (false);
        }
      } else {
      }
    });
  };

  const LabelTypeName = (schedule_day) => {
    setLoading (true);
    setScheduleDay(schedule_day)
    dispatch(getlabelView(schedule_day)).then((res) => {
      if (res?.payload === res?.payload) {
        // toast.success(res.payload.message);
        console.log("res", res?.payload?.data);
        setLabelData(res?.payload?.data);

        if (res?.payload?.data.length > 0) {
          toast.success("wait Label Sheet Download");
          setTimeout(() => {
            Autoclicklabel();
            setLoading (false);
          }, 1000);
        } else {
          toast.error("No Label Sheet Data");
          setLoading (false);
        }
      } else {
      }
    });
  };

  const MiqaatLabelTypeName = (schedule_day) => {
    setLoading (true);
    setScheduleDay(schedule_day)
    dispatch(getlabelView(schedule_day)).then((res) => {
      if (res?.payload === res?.payload) {
        // toast.success(res.payload.message);
        console.log("res", res?.payload?.data);
        setLabelData(res?.payload?.data);

        if (res?.payload?.data.length > 0) {
          toast.success("wait Label Sheet Download");
          setTimeout(() => {
            AutoclickMiqaatlabel();
            setLoading (false);
          }, 1000);
        } else {
          toast.error("No Label Sheet Data");
          setLoading (false);
        }
      } else {
      }
    });
  };

  const getIngredientsdata = (
    ingredient_id_array,
    id,
    schedule_day,
    schedule_type,
    count,
    is_niyaaz,
    menu
  ) => {
    const requestBody = {
      ingredient_id: [ingredient_id_array],
      schedule_id: id,
    };

    setScheduleDay (schedule_day)
    let url = schedule_type === "FMBTHALI" ? "ingredients-get-by-id" : "ingredients-get-by-id-miqat"

    if (schedule_type == "FMBTHALI" && is_niyaaz == '1'){
      url = "ingredients-get-by-id-fmb-niyaz"
    }

    fetch(fmb_food_items_ingredients + "admin/" + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          console.log("new data ", data.data);
          const ingredientscount = data.data;
          setingredientpdf(data?.data?.schedule);
          setingredientMenuSummarypdf(data?.data?.menu_summary);
          setingredientCountbyPortionpdf(data?.data?.Countbyportion);
          setScheduleType (schedule_type);
          setIsNiyaaz(is_niyaaz);
          setScheduleDay(schedule_day);
          setDataAll(schedule_day);
          setMenuData(menu);

          if (data?.data?.schedule.length > 0) {
            toast.success("Wait Ingredient Sheet Download");
            setTimeout(() => {
              Autoclickindred();
            }, 1000);
          } else {
            toast.error("No ingredient Sheet Data");
          }
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const getIngredients100data = (
    ingredient_id_array,
    id,
    schedule_day,
    schedule_type,
    count,
    is_niyaaz,
    menu
  ) => {
    const requestBody = {
      ingredient_id: [ingredient_id_array],
      schedule_id: id,
    };

    const url = schedule_type === "FMBTHALI" ? "ingredients-get-by-id" : "ingredients-get-by-id-miqat"

    fetch(fmb_food_items_ingredients + "admin/" + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          const ingredientscount = data.data;
          setingredientpdf(data?.data?.schedule);
          setingredientMenuSummarypdf(data?.data?.menu_summary);
          setingredientCountbyPortionpdf(data?.data?.Countbyportion);
          setScheduleType (schedule_type);
          setIsNiyaaz(is_niyaaz);
          setScheduleDay(schedule_day);
          setDataAll(schedule_day);
          setMenuData(menu);
          if (data?.data?.schedule.length > 0) {
            toast.success("Wait Ingredient Sheet Download");
            setTimeout(() => {
              Autoclickind100ref();
            }, 1000);
          } else {
            toast.error("No ingredient Sheet Data");
          }
          console.log("ingredientscount", data);
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  var dateName = function (date) {

    var arr = date.split(/[- :]/);

    var yyyy = arr[2]
    var mm = arr[0]
    var dd = arr[1]

    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var dayNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    var today = new Date(yyyy, mm - 1, dd);
    var dd = today.getDate();
    var dayname = dayNames[today.getDay() - 1] ? dayNames[today.getDay() - 1] : "Sunday";
    var mmName = monthNames[today.getMonth()];
    var yyyy = today.getFullYear();
    var fullDate = dayname + " - " + mm + "/" + dd + "/" + yyyy;
    return fullDate;
  };

  const [DataAll, setDataAll] = useState("");

  const ZoneTypeName = (schedule_day) => {
    setScheduleDay (schedule_day)
    dispatch(getzoneView(schedule_day)).then((res) => {
      if (res?.payload === res?.payload) {
        setZoneData(res?.payload?.data);
        setDataAll(schedule_day);
        if (res?.payload?.data.length > 0) {
          toast.success("Wait Zone Sheet Download");
          setTimeout(() => {
            Autoclickbutton();
          }, 1000);
        } else {
          toast.error("No Zone Sheet Data");
        }
      } else {
      }
    });
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleIngredientPrint = useReactToPrint({
    content: () => printRef.current,
  });

  function RemoveModal() {
    setIsRemoveOpen(true);
  }

  function RemovecloseModal() {
    setIsRemoveOpen(false);
  }

  function RemoveOpenModal() {}

  const styles = StyleSheet.create({
    zonesheetsection: {
      marginTop: 20,
      marginBottom: 5,
      marginLeft: 0,
      marginRight: 10,
    },

    zonesheetBottomsection: {
      marginTop: 0,
      marginBottom: 5,
      marginLeft: 0,
      marginRight: 10,
    },

    ingrediantsection: {
      marginBottom: 25,
      marginTop: 15,
      marginLeft: 10,
      marginRight: 10,
    },
    columnheading: {
      marginBottom: 10,
      marginTop: 10
    },
    ingrediantOuterView: {
      flexDirection: "column",
      marginTop: 15,
      marginLeft: 35,
      marginBottom: 50,
      // backgroundColor: 'yellow',
      maxWidth: "100%"
    },
    page: {
      flexDirection: "row",
      backgroundColor: "#FFF",
      marginTop: 10,
    },

    zoneSection: {
      margin: 10,
      padding: 10,
      marginLeft: 20,
      flexGrow: 1,
    },

    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    table: {
      display: "table",
    },
    tableRow: {
      flexDirection: "row",
      width: 400,
    },

    tableMenuRow: {
      flexDirection: "row",
      width: 400,
    },

    tableMainCell: {
      borderColor: "#000",
      borderWidth: 1,
      borderRight: "none",
      padding: 5,
      textAlign: "center",
    },

    tableMenuMainCell: {
      borderColor: "#000",
      borderWidth: 1,
      borderRight: "none",
      padding: 5
    },

    tableCell: {
      borderColor: "#000",
      borderWidth: 1,
      borderRight: "none",
      padding: 5,
      textAlign: "center",
      width: 600,
    },
    tableCellBottom: {
      borderColor: "#000",
      borderWidth: 1,
      marginLeft: -1,
      padding: 5,
      textAlign: "center",
    },


    tableZoneCell: {
      borderColor: "#000",
      borderWidth: 1,
      borderRight: "none",
      padding: 5,
      textAlign: "center",
      width: 1200,
    },
    tableZoneCellBottom: {
      borderColor: "#000",
      borderWidth: 1,
      padding: 5,
      textAlign: "center",
    },


    newApp: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      padding: 5,
      textAlign: "center",
    },

    newBottomApp: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      padding: 5,
      marginLeft: -1,
      textAlign: "center",
    },

    newMainCell: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      padding: 5,
      borderTop: "none",
      textAlign: "center",
    },

    newCell: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      padding: 5,
      borderTop: "none",
      marginLeft: -1,
      textAlign: "center",
    },

    dateCellMain: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      padding: 5
    },

    dateCellItem: {
      width: "110%",
    },

    thaalCellMain: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      borderRight: "none",
      padding: 5,
      textAlign: "center",
    },

    thaalCellItem: {
      width: "80%",
    },

    tableSellMain: {
      borderColor: "#000",
      borderWidth: 1,
      borderTop: "none",
      borderRight: "none",
      padding: 5,
    },

    tableRightMain: {
      width: "90%",
      maxWidth: "21%",
    },

    tableSellIng: {
      borderColor: "#000",
      borderWidth: 1,
      borderTop: "none",
      borderRight: "none",
      padding: 5,
    },

    tableSellZoneName: {
      borderColor: "#000",
      borderWidth: 1,
      borderTop: "none",
      borderRight: "none",
      padding: 5,
      fontSize: 10,
      textAlign: "left",
      width: 600
    },

    tableSell: {
      borderColor: "#000",
      borderWidth: 1,
      borderTop: "none",
      borderRight: "none",
      padding: 5,
      fontSize: 10,
      textAlign: "center",
      width: 600
    },
    columnIngredientHeader: {
      fontSize: 10,
      color: "#000",
      marginLeft: 50,
      marginTop: 30
    },
    columnHeader: {
      fontSize: 10,
      color: "#000",
    },
    tableWidth: {
      width: "110%",
      maxWidth: "24%",
    },

    tableZoneNameWidth: {
      width: "110%",
      maxWidth: "49%",
    },

    tableCommentswidth: {
      width: "110%",
      maxWidth: "37%",
    },


    tablePortionWidth: {
      width: "110%",
      maxWidth: "18%",
    },

    tablePhoneWidth: {
      width: "110%",
      maxWidth: "20%",
    },

    tableWidth: {
      width: "110%",
      maxWidth: "24%",
    },

    tableBottomWidth: {
      width: "110%",
      maxWidth: "14%",
    },

    tableZoneBottomWidth: {
      width: "110%",
      maxWidth: "24%",
    },

    tableWidthRiceRoti: {
      width: "100%",
      maxWidth: "8%",
    },

    tableInd: {
      width: "110%",
      maxWidth: "19%",
    },

    tabletdmenuItem: {
      maxWidth: "22%",
    },
    tabletdsubmenuItem: {
      width: "110%",
      maxWidth: "13%",
    },

    tableRight: {
      width: "110%",
      maxWidth: "51%",
    },

    tableMenuRight: {
      maxWidth: "320%",
      width: 1450,
    },

    tableMenuMainRight: {
      maxWidth: "190%",
      width: 950,
    },

    tableMenuLabelRight: {
      maxWidth: "320%",
      width: 1450,
    },

    textMainCell: {
      marginLeft: 80,
    },

    textMenuMainCell: {
      marginLeft: 10,
    },

    textDateCell: {
      marginLeft: 12,
      width: 100
    },

    textThaalDateCell: {
      width: 100
    },

    textIngCell:{
      marginLeft: -15,
    },

    textQtyCell:{
      marginLeft: -7,
    },

    textUnitCell:{
      marginLeft: -5,
    },

    tableIngRight: {
      width: "90%",
      maxWidth: "45%",
    },

    tableMenuIngRight: {
      width: "150%",
      maxWidth: "65%",
    },

    tableName: {
      width: "110%",
      maxWidth: "28%",
    },

    tableRo: {
      width: "110%",
      maxWidth: "50%",
    },
    columnLeader: {
      fontSize: 10,
      color: "#000",
    },
    manData: {
      flexDirection: "row",
    },
    flowFloat: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 8,
      fontSize: 13,
      marginRight: 30,
      width: 560
    },
    labelItem: {
      fontWeight: "bold", // Ensure fontWeight is applied within labelItem style
    },
  });
  const styleslist = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "row",
      fontWeight: "bold",
      flexWrap: "wrap",
      marginRight: 15,
      marginLeft: 15,
      // justifyContent: 'center', // Adjust as needed for spacing between items
      
    },
    labelSheet: {
      width: '145px',
      textAlign: "center",
      marginRight: 2,
      fontSize: 11,
      // backgroundColor: 'yellow',
      height: '72px',
      // borderColor: 'black',
      // borderWidth: 1
    },

    fatehaSheet: {
      width: "10%",
      textAlign: "center",
      marginBottom: 5,
      marginTop: 2,
      fontSize: 10,
    },

    labelItem: {
      // Adjust to allocate space for 2 items per row with a little gap in between
      // backgroundColor: 'lightgreen',
      // marginTop:5,
      // borderColor: 'black',
      // borderWidth: 1,
      fontWeight: "bold", // Ensure fontWeight is applied within labelItem style

      // marginBottom: 1
    },

    labelItemText: {
      fontSize: 10,
    },
    noData: {
      textAlign: "center",
      fontSize: 10,
      marginTop: 5,
    },
  });

  const FatehaPDF = () => {
    const itemsPerPage = 44; // Number of items per page
    const pageCount = Math.ceil(Labeldata.length / itemsPerPage); // Calculate total pages
    return (
      <Document>
        {Array.from(Array(pageCount), (el, pageIndex) => (
           <Page size="LETTER" key={pageIndex} style={styleslist.page}>
            {Labeldata.slice(
              pageIndex * itemsPerPage,
              (pageIndex + 1) * itemsPerPage
            ).map((item, index) => {
              const date = new Date(item.schedule_day);
              const datePart = date.toISOString().split("T")[0];

              return (
                <View key={index} style={styleslist.labelSheet}>
                  <Text style={{ fontSize: 9, ...styleslist.labelItem }}>
                    {" "}
                    <Text style={{ color: "black", fontSize: 10.5 }}> </Text>
                  </Text>
                  <Text style={{...styleslist.labelItem, marginTop:5}}>
                    <Text style={{ fontSize: 10, color: "black", fontFamily: 'Helvetica-Bold' }}>FATEHA</Text>{" "}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10.5,
                      color: "#323639",
                      marginTop:5,
                      ...styleslist.labelItem,
                    }}
                  >
                    <Text style={{ color: "#323639", fontSize: 10 }}>
                      {fatehaNamePDF}
                    </Text>
                  </Text>

                  <Text style={{ fontSize: 8, ...styleslist.labelItem }}>
                    {" "}
                  </Text>
                </View>
              );
            })}
          </Page>
        ))}
      </Document>
    );
  };

  const LabelPDF = () => {
    const itemsPerPage = 44; // Number of items per page
    const pageCount = Math.ceil(Labeldata.length / itemsPerPage); // Calculate total pages
    return (
      <Document>
        {Array.from(Array(pageCount), (el, pageIndex) => (
           <Page size="LETTER" key={pageIndex} style={styleslist.page}>
            {Labeldata.slice(
              pageIndex * itemsPerPage,
              (pageIndex + 1) * itemsPerPage
            ).map((item, index) => {
              const date = new Date(item.schedule_day);
              const datePart = date.toISOString().split("T")[0];
              var TotalLength = item?.designation.length + item?.full_name.length;


              var marginTopFlag = (index === 0 || index === 1 || index === 2 || index === 3) ? true : false
              var marginBottomFlag = (index === 0 || index === 1 || index === 2 || index === 3 || index === 40 || index === 41 || index === 42 || index === 43) ? true : false

              return (
                <View wrap={false} key={index} style={styleslist.labelSheet}>
                  <Text style={{ fontSize: marginTopFlag  == true ? 9 : 9, ...styleslist.labelItem, marginTop: marginTopFlag  == true ? 15 : 5 }}>
                    {moment(datePart).format("MM/DD/YYYY")} -{" "}
                   
                    {(item?.pickup_location_name === "1-a University Hills" || item?.pickup_location_name === "1-b Irving Heights") ? (
                      <Text style={{ color: "black", fontSize: marginTopFlag  == true ? 9 : 9, fontFamily: 'Helvetica-Bold', marginTop: marginTopFlag  == true ? 15 : 5 }}>
                      {item?.pickup_location_name}
                      </Text>
                    ) : (
                      <Text style={{ color: "black", fontSize: marginTopFlag  == true ? 10 : 10, fontFamily: 'Helvetica-Bold', marginTop: marginTopFlag  == true ? 15 : 5 }}>
                      {item?.pickup_location_name}
                    </Text>
                    )}
                  </Text>
                  <Text style={{...styleslist.labelItem, marginTop:marginBottomFlag == true ? 1 : 5}}>
                    <Text style={{ fontSize: 11, color: "green", fontFamily: 'Helvetica-Bold', marginTop:5 }}>
                      {item?.portion_type_name}
                    </Text>{" "}
                    {item?.allow_skip_roti === "1" ? (
                      <Text style={{ fontSize: 9, color: "red", marginTop:marginBottomFlag == true ? 1 : 5 }}>
                        {item?.want_roti == 1 ? "" : "- No Roti"}
                      </Text>
                    ) : (
                      ""
                    )}
                    {item?.allow_skip_rice === "1" ? (
                      <Text
                        style={{
                          fontSize: 9,
                          marginTop:marginBottomFlag == true ? 1 : 5,
                          color: "blue",
                          ...styleslist.labelItem,
                        }}
                      >
                        {item?.want_rice == 1 ? "" : "- No Rice"}
                      </Text>
                    ) : (
                      ""
                    )}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#00008B",
                      ...styleslist.labelItem,
                      marginTop: marginBottomFlag == true ? 1 : 5
                    }}
                  >
                    {TotalLength > 23 ? (
                      <Text style={{ color: "#00008B", fontSize: marginBottomFlag == true ? 8.5 : 8.5, fontFamily: 'Helvetica-Bold', marginTop:marginBottomFlag == true ? 1 : 2}}>
                        {item?.designation ? item?.designation : ""}{" "}
                        {item?.full_name}
                      </Text>
                    ) : (
                      <Text style={{ color: "#00008B", fontSize: 10, fontFamily: 'Helvetica-Bold', marginTop:marginBottomFlag == true ? 2 : 5 }}>
                      {item?.designation ? item?.designation : ""}{" "}
                      {item?.full_name}
                    </Text>
                    )}
                  </Text>

                  {TotalLength > 23 ? (
                    <Text style={{ fontSize: marginBottomFlag ? 8 : 8, ...styleslist.labelItem, marginTop:marginBottomFlag == true ? 1 : 2}}>
                      {item?.phone == "NULL" ? "-" : item?.phone}
                    </Text>
                    ) : (
                      <Text style={{ fontSize: marginBottomFlag ? 8 : 9, ...styleslist.labelItem, marginTop:marginBottomFlag == true ? 2 : 5 }}>
                      {item?.phone == "NULL" ? "-" : item?.phone}
                    </Text>
                    )}
                </View>
              );
            })}
          </Page>
        ))}
      </Document>
    );
  };

  const getTextColor = (item) => {

    console.log ("item logged", item)

    switch (item.pickup_location_name)
    {
    case "Valley Ranch":
    case "1-b Irving Heights":
    case "1-e S. Beltline":
    case "Northern Alliance":
        {
            return "#E22218"
        }
    case "1-c S. Nursery":
    case "Las Colinas":
    case "Arlington":
    case "Dallas":
        {
            return "#04B09E"
        }
    case "Coppell":
    case "UTA Students":
    case "1-d S. MacArthur":
    case "Grand Prairie":
        {
            return "#0065B2"
        }
    case "Euless-Grapevine":
    case "Denton":
    case "1-a University Hills":
    case "Dallas - North":
        {
            return "#A0793B"
        }
    default: return "#FFFFFF"
  }
}

const getBgColor = (item) => {

  console.log ("item logged", item)

  switch (item.pickup_location_name)
  {
  case "Valley Ranch":
  case "1-b Irving Heights":
  case "1-e S. Beltline":
  case "Northern Alliance":
      {
          return "#FFFFFF"
      }
  case "1-c S. Nursery":
  case "Las Colinas":
  case "Arlington":
  case "Dallas":
      {
          return "#FFFFFF"
      }
  case "Coppell":
  case "UTA Students":
  case "1-d S. MacArthur":
  case "Grand Prairie":
      {
          return "#FFFFFF"
      }
  case "Euless-Grapevine":
  case "Denton":
  case "1-a University Hills":
  case "Dallas - North":
      {
          return "gray"
      }
  default: return "#FFFFFF"
}
}

  const MiqaatLabelPDF = () => {
    const itemsPerPage = 44; // Number of items per page
    const pageCount = Math.ceil(Labeldata.length / itemsPerPage); // Calculate total pages
    return (
      <Document>
        {Array.from(Array(pageCount), (el, pageIndex) => (
           <Page size="LETTER" key={pageIndex} style={styleslist.page}>
            {Labeldata.slice(
              pageIndex * itemsPerPage,
              (pageIndex + 1) * itemsPerPage
            ).map((item, index) => {
              const date = new Date(item.schedule_day);
              const datePart = date.toISOString().split("T")[0];
              var TotalLength = item?.designation.length + item?.full_name.length;


              var marginTopFlag = (index === 0 || index === 1 || index === 2 || index === 3) ? true : false
              var marginBottomFlag = (index === 0 || index === 1 || index === 2 || index === 3 || index === 40 || index === 41 || index === 42 || index === 43) ? true : false

              return (
                <View wrap={false} key={index} style={styleslist.labelSheet}>
                  <Text style={{ fontSize: marginTopFlag  == true ? 9 : 9, ...styleslist.labelItem, marginTop: marginTopFlag  == true ? 15 : 5 }}>
                    {moment(datePart).format("MM/DD/YYYY")} -{" "}
                   
                    {(item?.pickup_location_name === "1-a University Hills" || item?.pickup_location_name === "1-b Irving Heights") ? (
                      <Text style={{ backgroundColor: "#FFFFFF", color: getTextColor(item), fontSize: marginTopFlag  == true ? 9 : 9, fontFamily: 'Helvetica-Bold', marginTop: marginTopFlag  == true ? 15 : 5 }}>
                      {item?.pickup_location_name}
                      </Text>
                    ) : (
                      <Text style={{ backgroundColor: "#FFFFFF", color: getTextColor(item), fontSize: marginTopFlag  == true ? 10 : 10, fontFamily: 'Helvetica-Bold', marginTop: marginTopFlag  == true ? 15 : 5 }}>
                      {item?.pickup_location_name}
                    </Text>
                    )}
                  </Text>
                  <Text style={{...styleslist.labelItem, marginTop:marginBottomFlag == true ? 1 : 5}}>
                    <Text style={{ fontSize: 11, color: "black", fontFamily: 'Helvetica-Bold', marginTop:5 }}>
                      {item?.portion_type_name}
                    </Text>{" "}
                    {item?.allow_skip_roti === "1" ? (
                      <Text style={{ fontSize: 9, color: "red", marginTop:marginBottomFlag == true ? 1 : 5 }}>
                        {item?.want_roti == 1 ? "" : "- No Roti"}
                      </Text>
                    ) : (
                      ""
                    )}
                    {item?.allow_skip_rice === "1" ? (
                      <Text
                        style={{
                          fontSize: 9,
                          marginTop:marginBottomFlag == true ? 1 : 5,
                          color: "blue",
                          ...styleslist.labelItem,
                        }}
                      >
                        {item?.want_rice == 1 ? "" : "- No Rice"}
                      </Text>
                    ) : (
                      ""
                    )}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#00008B",
                      ...styleslist.labelItem,
                      marginTop: marginBottomFlag == true ? 1 : 5
                    }}
                  >
                    {TotalLength > 23 ? (
                      <Text style={{ color: "black", fontSize: marginBottomFlag == true ? 8.5 : 8.5, fontFamily: 'Helvetica-Bold', marginTop:marginBottomFlag == true ? 1 : 2}}>
                        {item?.designation ? item?.designation : ""}{" "}
                        {item?.full_name}
                      </Text>
                    ) : (
                      <Text style={{ color: "black", fontSize: 10, fontFamily: 'Helvetica-Bold', marginTop:marginBottomFlag == true ? 2 : 5 }}>
                      {item?.designation ? item?.designation : ""}{" "}
                      {item?.full_name}
                    </Text>
                    )}
                  </Text>

                  {TotalLength > 23 ? (
                    <Text style={{ fontSize: marginBottomFlag ? 8 : 8, ...styleslist.labelItem, marginTop:marginBottomFlag == true ? 1 : 2}}>
                      {item?.phone == "NULL" ? "-" : item?.phone}
                    </Text>
                    ) : (
                      <Text style={{ fontSize: marginBottomFlag ? 8 : 9, ...styleslist.labelItem, marginTop:marginBottomFlag == true ? 2 : 5 }}>
                      {item?.phone == "NULL" ? "-" : item?.phone}
                    </Text>
                    )}
                </View>
              );
            })}
          </Page>
        ))}
      </Document>
    );
  };

  const generatePDF = () => (
    <Document>
      {zonedata?.map((item, index) => (
        <Page size="LETTER" key={index} style={styles.page}>
          <View style={styles.zoneSection}>
            <View style={styles.flowFloat}>
              {" "}
              <Text>{item.location}</Text><Text>{dateName(DataAll)}</Text>
              {" "}
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableZoneCell, styles.tableZoneNameWidth]}>
                  <Text>Name</Text>
                </View>
                <View style={[styles.tableZoneCell, styles.tablePortionWidth]}>
                  <Text>Portion</Text>
                </View>
                <View style={[styles.tableZoneCell, styles.tablePhoneWidth]}>
                  <Text>Phone</Text>
                </View>
                <View style={[styles.tableZoneCell, styles.tableWidthRiceRoti]}>
                  <Text>Rice</Text>
                </View>
                <View style={[styles.tableZoneCell, styles.tableWidthRiceRoti]}>
                  <Text>Roti</Text>
                </View>
                <View style={[styles.tableCommentswidth, styles.newApp]}>
                  <Text>Comments</Text>
                </View>
                {/* <View style={[styles.tableCell,styles.tableWidth]  }><Text>Comments</Text></View> */}
              </View>
              {item?.users?.map((itemall, idx) => (
                <View key={idx} style={styles.tableRow}>
                  <View
                    style={[
                      styles.tableSellZoneName,
                      styles.tableZoneNameWidth,
                      styles.columnLeader,
                    ]}
                    numberOfLines={2}
                    ellipsizeMode="tail"
                  >
                    <Text>
                      {itemall?.full_name}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableSell,
                      styles.tablePortionWidth,
                      styles.columnLeader,
                    ]}
                  >
                    <Text>{itemall?.portion_type_name}</Text>
                  </View>
                  <View
                    style={[
                      styles.tableSell,
                      styles.tablePhoneWidth,
                      styles.columnLeader,
                    ]}
                  >
                    <Text>
                      {itemall?.phone == "NULL" ? "-" : itemall?.phone}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableSell,
                      styles.tableWidthRiceRoti,
                      styles.columnLeader,
                    ]}
                  >
                  { itemall.allow_skip_rice != "0" ?
                    <Text>
                     {itemall?.want_rice === 1 ? "Y" : itemall?.want_rice === 0 ? "N": "-"}
                    </Text>
                    :
                    <Text> - </Text>
                  }
                  </View>
                  <View
                    style={[
                      styles.tableSell,
                      styles.tableWidthRiceRoti,
                      styles.columnLeader,
                    ]}
                  >
                  {itemall.allow_skip_roti != "0" ?
                    <Text>
                      {itemall?.want_roti === 1
                        ? "Y"
                        : itemall?.want_roti === 0
                          ? "N"
                          : "-"}
                    </Text>
                    :
                    <Text> - </Text>
                  }
                  </View>
                  <View
                    style={[
                      styles.newMainCell,
                      styles.tableCommentswidth,
                      styles.columnLeader,
                    ]}
                  >
                    <Text>
                        {itemall?.zone_sheet_notes != '' ? itemall?.zone_sheet_notes : ""}
                    </Text>
                    <Text>
                        {(itemall?.source == "Mobile" || itemall?.source == "0" || itemall?.source == "Web") ? "" : itemall?.source}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View wrap={false} style={styles.zonesheetsection}>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
              {item?.counts?.count_1_2_people == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text>1-2 people</Text>
                      </View>
                    )}
              {item?.counts?.count_3_4_people == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text>3-4 People</Text>
                      </View>
                    )}
              {item?.counts?.count_5_6_people == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text>5-6 People</Text>
                      </View>
                    )}
              {item?.counts?.count_x_people == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text>X Small</Text>
                      </View>
                    )}
              </View>
              <View style={[styles.tableRow, styles.columnHeader]}>
              {item?.counts?.count_1_2_people == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_1_2_people
                            ? item?.counts?.count_1_2_people
                            : "-"}
                        </Text>
                      </View>
                    )}
                {item?.counts?.count_3_4_people == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_3_4_people
                            ? item?.counts?.count_3_4_people
                            : "-"}
                        </Text>
                      </View>
                    )}
                {item?.counts?.count_5_6_people == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_5_6_people
                            ? item?.counts?.count_5_6_people
                            : "-"}
                        </Text>
                      </View>
                    )}
                {item?.counts?.count_x_people == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_x_people
                            ? item?.counts?.count_x_people
                            : "-"}
                        </Text>
                      </View>
                    )}
                {/* Add more cells for additional columns */}
              </View>
              </View>
            </View>

            {item?.counts.allow_skip_roti == "0" || (item?.counts?.count_1_2_no_roti == "0" &&
            item?.counts?.count_3_4_no_roti == "0" &&
            item?.counts?.count_5_6_no_roti == "0" &&
            item?.counts?.count_x_no_roti == "0") ? (
              <></>
            ) : (
              <View wrap={false} style={styles.zonesheetBottomsection}>
                <View style={[styles.columnHeader, styles.columnheading]}>
                  <Text> Count by Portion - With NO Roti</Text>
                </View>
                <View style={styles.table}>
                  <View style={[styles.tableRow, styles.columnHeader]}>
                    {item?.counts?.count_1_2_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text>1-2 people </Text>
                      </View>
                    )}

                    {item?.counts?.count_3_4_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 3-4 People</Text>
                      </View>
                    )}

                    {item?.counts?.count_5_6_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 5-6 People</Text>
                      </View>
                    )}

                  {item?.counts?.count_x_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> X Small</Text>
                      </View>
                    )}
                  </View>

                  <View key={index} style={styles.tableRow}>
                    {item?.counts?.count_1_2_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_1_2_no_roti
                            ? item?.counts?.count_1_2_no_roti
                            : "-"}
                        </Text>
                      </View>
                    )}

                    {item?.counts?.count_3_4_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_3_4_no_roti
                            ? item?.counts?.count_3_4_no_roti
                            : "-"}
                        </Text>
                      </View>
                    )}

                    {item?.counts?.count_5_6_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_5_6_no_roti
                            ? item?.counts?.count_5_6_no_roti
                            : "-"}
                        </Text>
                      </View>
                    )}

                    {item?.counts?.count_x_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_x_no_roti
                            ? item?.counts?.count_x_no_roti
                            : "-"}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>
            )}

            {item?.counts.allow_skip_rice == "0" || (item?.counts?.count_1_2_no_rice == "0" &&
            item?.counts?.count_3_4_no_rice == "0" &&
            item?.counts?.count_5_6_no_rice == "0" &&
            item?.counts?.count_x_no_rice == "0") ? (
              <></>
            ) : (
              <View wrap={false} style={styles.zonesheetBottomsection}>
                <View style={[styles.columnHeader, styles.columnheading]}>
                  <Text> Count by Portion - With NO Rice</Text>
                </View>
                <View style={styles.table}>
                  <View style={[styles.tableRow, styles.columnHeader]}>
                    {item?.counts?.count_1_2_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text>1-2 people </Text>
                      </View>
                    )}

                    {item?.counts?.count_3_4_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 3-4 People</Text>
                      </View>
                    )}

                    {item?.counts?.count_5_6_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 5-6 People</Text>
                      </View>
                    )}
                    {item?.counts?.count_x_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> X Small</Text>
                      </View>
                    )}
                  </View>

                  <View key={index} style={styles.tableRow}>
                    {item?.counts?.count_1_2_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_1_2_no_rice
                            ? item?.counts?.count_1_2_no_rice
                            : "-"}
                        </Text>
                      </View>
                    )}

                    {item?.counts?.count_3_4_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_3_4_no_rice
                            ? item?.counts?.count_3_4_no_rice
                            : "-"}
                        </Text>
                      </View>
                    )}

                    {item?.counts?.count_5_6_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_5_6_no_rice
                            ? item?.counts?.count_5_6_no_rice
                            : "-"}
                        </Text>
                      </View>
                    )}

                    {item?.counts?.count_x_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_x_no_rice
                            ? item?.counts?.count_x_no_rice
                            : "-"}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>
            )}

            {/* Display other information like counts */}
          </View>
        </Page>
      ))}
    </Document>
  );

  const generateINGREDIENTS = () => (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.ingrediantOuterView}>
          <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text>Menu & Grocery List</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableMenuMainCell, styles.tableMenuMainRight]}>
                  {menudata &&
                    JSON.parse(menudata).map((menu, index) => (
                      <>
                        {" "}
                        <Text style={[styles.textMenuMainCell]} key={index}>{menu ? menu : "-"} </Text>
                      </>
                    ))}
                </View>
                {scheduleType == "MIQAAT" &&
                <View style={[styles.thaalCellMain, styles.thaalCellItem]}>
                  <Text style={[styles.textThaalDateCell]}>Thaal Count: {ingredientpdf[0].thaal_count}</Text>
                </View>
                }
                <View style={[styles.dateCellMain, styles.dateCellItem]}>
                  <Text style={[styles.textDateCell]}>{dateName(DataAll) ? dateName(DataAll) : ""}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.zonesheetBottomsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text>Ingredient List</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableMenuIngRight]}>
                  <Text style={[styles.textIngCell]}>Menu Item</Text>
                </View>
                <View style={[styles.tableCell, styles.tableIngRight]}>
                  <Text style={[styles.textIngCell]}>Ingredient</Text>
                </View>
                <View style={[styles.tableCell, styles.tabletdmenuItem]}>
                  <Text style={[styles.textQtyCell]}>Quantity</Text>
                </View>
                <View style={[styles.newApp, styles.tabletdsubmenuItem]}>
                  <Text style={[styles.textUnitCell]}>Unit</Text>
                </View>
              </View>
              {Array.isArray(ingredientpdf) &&
                ingredientpdf.map((itemall) => (
                  <View key={itemall.id} style={[styles.tableRow]}>
                    <View
                      style={[styles.tableSellMain, styles.tableMenuIngRight, styles.columnLeader]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      <Text>
                        {" "}
                        {itemall.dish_name}
                      </Text>
                    </View>

                    <View
                      style={[
                        styles.tableSellIng,
                        styles.tableIngRight,
                        styles.columnLeader,
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      <Text>{itemall?.ingredient_name}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      <Text style={[styles.textQtyCell]}>
                        {itemall?.total_quantity !== null
                          ? round(itemall?.total_quantity)
                          : "-"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newMainCell,
                        styles.tabletdsubmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      <Text style={[styles.textUnitCell]}>
                        {itemall?.unit !== null
                          ? itemall?.unit
                          : "-"}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          </View>

          {scheduleType == "FMBTHALI" &&

          <View wrap={false} style={styles.zonesheetBottomsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text> Daily Count</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>Only X-Small </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> Only 1-2</Text> <Text> People(Half) </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>Only 3-4</Text> <Text> People(Full) </Text>
                </View>
                <View style={[styles.tableInd, styles.newBottomApp]}>
                  <Text>Total 3-4</Text> <Text> People(Full) </Text>
                </View>
              </View>
              {Array.isArray(ingredientMenuSummarrypdf) &&
                ingredientMenuSummarrypdf.map((itemall) => (
                  <View key={itemall.id} style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      <Text>{itemall?.x_small}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        <Text>
                          {itemall?.one_two_THAALIS && itemall?.five_six_THAALIS
                            ? itemall.one_two_THAALIS + itemall.five_six_THAALIS
                            : "NA"}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.three_four_THAALIS &&
                        itemall?.five_six_THAALIS
                          ? itemall.three_four_THAALIS +
                            itemall.five_six_THAALIS
                          : "NA"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newCell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.total_full_thaali
                          ? itemall?.total_full_thaali
                          : "Na"}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          </View>
           }
          {scheduleType == "FMBTHALI" &&
          <View wrap={false} style={styles.ingrediantsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text> Count by Portion - Total Including All</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>1-2 people </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> 3-4 People</Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> 5-6 People</Text>
                </View>
                <View style={[styles.tableInd, styles.newBottomApp]}>
                  <Text>X-Small</Text>
                </View>
                <View style={[styles.tableInd, styles.newBottomApp]}>
                  <Text>Total Count</Text>
                </View>
              </View>
              {Array.isArray(ingredientMenuSummarrypdf) &&
                ingredientMenuSummarrypdf.map((itemall) => (
                  <View key={itemall.id} style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>{itemall?.one_two_THAALIS}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.three_four_THAALIS
                          ? itemall?.three_four_THAALIS
                          : "-"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.five_six_THAALIS
                          ? itemall?.five_six_THAALIS
                          : "-"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newCell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>{itemall?.x_small ? itemall?.x_small : "-"}</Text>
                    </View>
                    <View
                      style={[
                        styles.newCell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>{itemall?.total_count ? round(itemall?.total_count) : "-"}</Text>
                    </View>
                  </View>
                ))}
            </View>
            {ingredientcountbyportionpdf?.allow_skip_roti == "0" ||
            (ingredientcountbyportionpdf?.count_portion_2_no_roti == "0" &&
            ingredientcountbyportionpdf?.count_portion_3_no_roti == "0" &&
            ingredientcountbyportionpdf?.count_portion_4_no_roti == "0" &&
            ingredientcountbyportionpdf?.count_portion_1_no_roti == "0") ? (
              <></>
            ) : (
              <View wrap={false} style={styles.zonesheetBottomsection}>
                <View style={[styles.columnHeader, styles.columnheading]}>
                  <Text> Count by Portion - With NO Roti</Text>
                </View>
                <View style={styles.table}>
                  <View style={[styles.tableRow, styles.columnHeader]}>
                    {ingredientcountbyportionpdf?.count_portion_2_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text>1-2 people </Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_3_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 3-4 People</Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_4_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 5-6 People</Text>
                      </View>
                    )}
                    {ingredientcountbyportionpdf?.count_portion_1_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> X Small</Text>
                      </View>
                    )}
                  </View>

                  <View style={styles.tableRow}>
                    {ingredientcountbyportionpdf?.count_portion_2_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_2_no_roti
                            ? ingredientcountbyportionpdf?.count_portion_2_no_roti
                            : "-"}
                        </Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_3_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_3_no_roti
                            ? ingredientcountbyportionpdf?.count_portion_3_no_roti
                            : "-"}
                        </Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_4_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_4_no_roti
                            ? ingredientcountbyportionpdf?.count_portion_4_no_roti
                            : "-"}
                        </Text>
                      </View>
                    )}
                    {ingredientcountbyportionpdf?.count_portion_1_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_1_no_roti
                            ? ingredientcountbyportionpdf?.count_portion_1_no_roti
                            : "-"}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>
            )}

            { ingredientcountbyportionpdf?.allow_skip_rice == "0" ||
            (ingredientcountbyportionpdf?.count_portion_2_no_rice == "0" &&
            ingredientcountbyportionpdf?.count_portion_3_no_rice == "0" &&
            ingredientcountbyportionpdf?.count_portion_4_no_rice == "0" &&
            ingredientcountbyportionpdf?.count_portion_1_no_rice == "0") ? (
              <></>
            ) : (
              <View wrap={false} style={styles.zonesheetBottomsection}>
                <View style={[styles.columnHeader, styles.columnheading]}>
                  <Text> Count by Portion - With NO Rice</Text>
                </View>
                <View style={styles.table}>
                  <View style={[styles.tableRow, styles.columnHeader]}>
                    {ingredientcountbyportionpdf?.count_portion_2_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text>1-2 People </Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_3_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 3-4 People</Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_4_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 5-6 People</Text>
                      </View>
                    )}
                    {ingredientcountbyportionpdf?.count_portion_1_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> X Small</Text>
                      </View>
                    )}
                  </View>

                  <View style={styles.tableRow}>
                    {ingredientcountbyportionpdf?.count_portion_2_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_2_no_rice
                            ? ingredientcountbyportionpdf?.count_portion_2_no_rice
                            : "-"}
                        </Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_3_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_3_no_rice
                            ? ingredientcountbyportionpdf?.count_portion_3_no_rice
                            : "-"}
                        </Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_4_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_4_no_rice
                            ? ingredientcountbyportionpdf?.count_portion_4_no_rice
                            : "-"}
                        </Text>
                      </View>
                    )}
                    {ingredientcountbyportionpdf?.count_portion_1_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_1_no_rice
                            ? ingredientcountbyportionpdf?.count_portion_1_no_rice
                            : "-"}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>
            )}
          </View>
          }
        </View>
      </Page>
    </Document>
  );



  const generate100INGREDIENTS = () => (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.ingrediantOuterView}>
          <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text>Menu & Grocery List</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableMenuRow, styles.columnHeader]}>
                <View style={[styles.tableMenuMainCell, styles.tableMenuMainRight]}>
                  {menudata &&
                    JSON.parse(menudata).map((menu, index) => (
                      <>
                        {" "}
                        <Text style={[styles.textMenuMainCell]} key={index}>{menu ? menu : "NA"} </Text>
                      </>
                    ))}
                </View>
                {scheduleType == "MIQAAT" &&
                <View style={[styles.thaalCellMain, styles.thaalCellItem]}>
                  <Text style={[styles.textThaalDateCell]}>Thaal Count: 10</Text>
                </View>
                }
                <View style={[styles.dateCellMain, styles.dateCellItem]}>
                  <Text style={[styles.textDateCell]}>{dateName(DataAll) ? dateName(DataAll) : ""}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.zonesheetBottomsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text>{scheduleType === "FMBTHALI" ? "Ingredient List (100 Thaalis Count)" : "Ingredient List (10 Thaals Count)"}</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableMenuIngRight]}>
                  <Text style={[styles.textIngCell]}>Menu Item</Text>
                </View>
                <View style={[styles.tableCell, styles.tableIngRight]}>
                  <Text style={[styles.textIngCell]}>Ingredient</Text>
                </View>
                <View style={[styles.tableCell, styles.tabletdmenuItem]}>
                  <Text style={[styles.textQtyCell]}>Quantity</Text>
                  <Text style={[styles.textQtyCell]}>{scheduleType === "FMBTHALI" ? "100 Thaalis" : "10 Thaals"}</Text>
                </View>
                <View style={[styles.newApp, styles.tabletdsubmenuItem]}>
                  <Text style={[styles.textUnitCell]}>Unit</Text>
                </View>
              </View>
              {Array.isArray(ingredientpdf) &&
                ingredientpdf.map((itemall) => (
                  <View key={itemall.id} style={[styles.tableRow]}>
                    <View
                      style={[styles.tableSellMain, styles.tableMenuIngRight, styles.columnLeader]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      <Text>
                        {" "}
                        {itemall.dish_name}
                      </Text>
                    </View>

                    <View
                      style={[
                        styles.tableSellIng,
                        styles.tableIngRight,
                        styles.columnLeader,
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      <Text>{itemall?.ingredient_name}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      <Text style={[styles.textQtyCell]}>
                        {itemall?.menu_quantity !== null
                          ? round(itemall?.menu_quantity)
                          : "-"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newMainCell,
                        styles.tabletdsubmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      <Text style={[styles.textUnitCell]}>
                        {itemall?.unit !== null
                          ? itemall?.unit
                          : "-"}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          </View>

          {/* <View wrap={false} style={styles.ingrediantsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text> Daily Count</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>Only X-Small </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> Only 1-2</Text> <Text> People(Half) </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>Only 3-4</Text> <Text> People(Full) </Text>
                </View>
                <View style={[styles.tableInd, styles.newBottomApp]}>
                  <Text>Total 3-4</Text> <Text> People(Full) </Text>
                </View>
              </View>
              {Array.isArray(ingredientMenuSummarrypdf) &&
                ingredientMenuSummarrypdf.map((itemall) => (
                  <View key={itemall.id} style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      <Text>{itemall?.x_small}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        <Text>
                          {itemall?.one_two_THAALIS && itemall?.five_six_THAALIS
                            ? itemall.one_two_THAALIS + itemall.five_six_THAALIS
                            : "NA"}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.three_four_THAALIS &&
                        itemall?.five_six_THAALIS
                          ? itemall.three_four_THAALIS +
                            itemall.five_six_THAALIS
                          : "NA"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newCell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.total_full_thaali
                          ? itemall?.total_full_thaali
                          : "Na"}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          </View> */}

          {/* <View wrap={false} style={styles.ingrediantsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text> Count by Portion - Total Including All</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>1-2 people </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> 3-4 People</Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> 5-6 People</Text>
                </View>
                <View style={[styles.tableInd, styles.newBottomApp]}>
                  <Text>X-Small</Text>
                </View>
                <View style={[styles.tableInd, styles.newBottomApp]}>
                  <Text>Total Count</Text>
                </View>
              </View>
              {Array.isArray(ingredientMenuSummarrypdf) &&
                ingredientMenuSummarrypdf.map((itemall) => (
                  <View key={itemall.id} style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>{itemall?.one_two_THAALIS}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.three_four_THAALIS
                          ? itemall?.three_four_THAALIS
                          : "-"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.five_six_THAALIS
                          ? itemall?.five_six_THAALIS
                          : "-"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newCell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>{itemall?.x_small ? itemall?.x_small : "-"}</Text>
                    </View>
                    <View
                      style={[
                        styles.newCell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>{itemall?.total_full_thaali ? round(itemall?.total_full_thaali) : "-"}</Text>
                    </View>
                  </View>
                ))}
            </View>
            {ingredientcountbyportionpdf?.count_portion_2_no_roti == "0" &&
            ingredientcountbyportionpdf?.count_portion_3_no_roti == "0" &&
            ingredientcountbyportionpdf?.count_portion_4_no_roti == "0" &&
            ingredientcountbyportionpdf?.count_portion_1_no_roti == "0" ? (
              <></>
            ) : (
              <View wrap={false} style={styles.zonesheetBottomsection}>
                <View style={[styles.columnHeader, styles.columnheading]}>
                  <Text> Count by Portion - With NO Roti</Text>
                </View>
                <View style={styles.table}>
                  <View style={[styles.tableRow, styles.columnHeader]}>
                    {ingredientcountbyportionpdf?.count_portion_2_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text>1-2 people </Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_3_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 3-4 People</Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_4_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 5-6 People</Text>
                      </View>
                    )}
                    {ingredientcountbyportionpdf?.count_portion_1_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> X Small</Text>
                      </View>
                    )}
                  </View>

                  <View style={styles.tableRow}>
                    {ingredientcountbyportionpdf?.count_portion_2_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_2_no_roti
                            ? ingredientcountbyportionpdf?.count_portion_2_no_roti
                            : "-"}
                        </Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_3_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_3_no_roti
                            ? ingredientcountbyportionpdf?.count_portion_3_no_roti
                            : "-"}
                        </Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_4_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_4_no_roti
                            ? ingredientcountbyportionpdf?.count_portion_4_no_roti
                            : "-"}
                        </Text>
                      </View>
                    )}
                    {ingredientcountbyportionpdf?.count_portion_1_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_1_no_roti
                            ? ingredientcountbyportionpdf?.count_portion_1_no_roti
                            : "-"}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>
            )}

            {ingredientcountbyportionpdf?.count_portion_2_no_rice == "0" &&
            ingredientcountbyportionpdf?.count_portion_3_no_rice == "0" &&
            ingredientcountbyportionpdf?.count_portion_4_no_rice == "0" &&
            ingredientcountbyportionpdf?.count_portion_1_no_rice == "0"? (
              <></>
            ) : (
              <View wrap={false} style={styles.zonesheetBottomsection}>
                <View style={[styles.columnHeader, styles.columnheading]}>
                  <Text> Count by Portion - With NO Rice</Text>
                </View>
                <View style={styles.table}>
                  <View style={[styles.tableRow, styles.columnHeader]}>
                    {ingredientcountbyportionpdf?.count_portion_2_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text>1-2 People </Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_3_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 3-4 People</Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_4_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 5-6 People</Text>
                      </View>
                    )}
                    {ingredientcountbyportionpdf?.count_portion_1_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> X Small</Text>
                      </View>
                    )}
                  </View>

                  <View style={styles.tableRow}>
                    {ingredientcountbyportionpdf?.count_portion_2_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_2_no_rice
                            ? ingredientcountbyportionpdf?.count_portion_2_no_rice
                            : "-"}
                        </Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_3_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_3_no_rice
                            ? ingredientcountbyportionpdf?.count_portion_3_no_rice
                            : "-"}
                        </Text>
                      </View>
                    )}

                    {ingredientcountbyportionpdf?.count_portion_4_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_4_no_rice
                            ? ingredientcountbyportionpdf?.count_portion_4_no_rice
                            : "-"}
                        </Text>
                      </View>
                    )}
                    {ingredientcountbyportionpdf?.count_portion_1_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {ingredientcountbyportionpdf?.count_portion_1_no_rice
                            ? ingredientcountbyportionpdf?.count_portion_1_no_rice
                            : "-"}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>
            )}
          </View> */}
        </View>
      </Page>
    </Document>
  );

  const buttonRef = useRef(null);

  const Autoclickbutton = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const LabelRef = useRef(null);
  const MiqaatLabelRef = useRef(null);
  const fatehaRef = useRef(null);

  const Autoclicklabel = () => {
    if (LabelRef.current) {
      LabelRef.current.click();
    }
  };

  const AutoclickMiqaatlabel = () => {
    if (MiqaatLabelRef.current) {
      MiqaatLabelRef.current.click();
    }
  };

  const AutoclickFateha = () => {
    if (fatehaRef.current) {
      fatehaRef.current.click();
    }
  };

  const ingredientRef = useRef(null);
  const ingredient100Ref = useRef(null);


  const Autoclickindred = () => {
    if (ingredientRef.current) {
      ingredientRef.current.click();
    }

    setTimeout(() => {
      window.location.reload()
    }, 2000);
  };

  const Autoclickind100ref = () => {
    if (ingredient100Ref.current) {
      ingredient100Ref.current.click();
    }

    setTimeout(() => {
      window.location.reload()
    }, 2000);
    
  };

  useEffect(() => {
    Thalischedulelist();
    getDishtypedata();
    getCatererData();
    getThaliData();
    getnewdishlist();
  }, []);
  


/***** */

const [selectedDate, setSelectedDate] = useState(null);
const [editshowPopup, setEditShowPopup] = useState(false);
const [showview, setShowview] = useState(false);
const popupRef = useRef(null);
const [getthalidataall, setGetThaliDataAll] = useState([]);
const Thaliviewdata = Object.values(getthalidataall);
const [disheditdata, setDishEditdata] = useState([]);
const [selectedOption, setSelectedOption] = useState("FMBTHALI");
const [jamantype, setJamanType] = useState("COOKING");
const [isDivVisible, setDivVisible] = useState(false);
const [catererfist, setCatererfirst] = useState("");
const [caterersecond, setCaterersecond] = useState("");
const [fatehaname, setFatehaName] = useState("");
const [allowSkipRoti, setAllowSkipRoti] = useState("0");
const [allowSkipRice, setAllowSkipRice] = useState("0");
const [order, setOrder] = useState("");
const selectedDatelist = new Date(selectedDate);
const nextThursdayDate = getNextThursday(selectedDatelist);
const [alldatelist, setAllDateList] = useState(nextThursdayDate);
const [endtime, setEndTime] = useState({ value: "1", label: "10.00 AM" });
const [dishid, setDishId] = useState(null);
const [savedraft, setSaveDraft] = useState("0");
const [dishtypeid, setDishTypeId] = useState('');
const [Viswthali, setViswthali] = useState("");
const [comment, setComment] = useState("");
const [disheditdataname, setDishNameData] = useState([]);
const [catererlist, setCatererlist] = useState([]);
const [addDish, setAddDish] = React.useState(false);
const [repetitioncount, setRepetitionCount] = useState('');
const [optiondata, setoptiondata] = useState("");
const [dishnamelistdata, setDishNameListData] = useState("");
const [cleartype, setCleartype] = useState([]);
const [duplicateCount, setDuplicateCount] = useState(0);
const [newdishlist, setNewDishList] = useState([]);
const [note, setNote] = useState("");
const currentDate = moment(selectedDate).format('YYYY-MM-DD');
const [selectedroti, setSelecteRoti] = useState("HALF");
const [events, setEvents] = useState([]);
const [showPopup, setShowPopup] = useState(false);
const [thalidata, setThalidata] = useState([]);
const [dishtypelist, setDishTypeList] = useState("");
const [dishNamelist, setDishNameList] = useState("");
const [Defultdishtype, setDefultDishType] = useState({ value: "", label: '' });
const [type, setType] = useState([]);
const [namedishtype, setNameDishType] = useState("");
const [selecteddish, setSelectedDish] = useState("FULL");
const [storedDishes, setStoredDishes] = useState([]);
const [RemoveMenuIsOpen, setIsRemoveMenuOpen] = React.useState(false);
const [selectedMenuId, setSelectedMenuId] = React.useState(false);
const [inputValues, setInputValues] = useState({}); // State to manage input values by schedule_id

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const niyaazdish = selectedOption === "FMBTHALI" ? 0 : 1;

const preventMinus = (e) => {
  if (e.code === 'Minus') {
      e.preventDefault();
  }
};

const getInputValue = (scheduleId) => {
  const selectedItem = Thalischeduledata.find(item => item.id === scheduleId);
  return selectedItem ? selectedItem.thaal_count : '';
};

const handleInputChange = (e, scheduleId) => {
  const newCount = e.target.value;
  updateInputValue(scheduleId, newCount);
};


const updateInputValue = (scheduleId, value) => {
  const updatedItems = Thalischeduledata.map(item => {
      if (item.id === scheduleId) {
          return {
              ...item,
              thaal_count: value
          };
      }
      return item;
  });
  setThaliScheduleData(updatedItems);
};

const handleMainCourseInputChange = (e, scheduleId) => {
  const newCount = e.target.value;
  updateMainCourseInputValue(scheduleId, newCount);
};

const getMainCourseInputValue = (scheduleId) => {
  const selectedItem = Thalischeduledata.find(item => item.id === scheduleId);
  return selectedItem ? selectedItem.thaal_main_course_count : '';
};

const updateMainCourseInputValue = (scheduleId, value) => {
  const updatedItems = Thalischeduledata.map(item => {
      if (item.id === scheduleId) {
          return {
              ...item,
              thaal_main_course_count: value
          };
      }
      return item;
  });
  setThaliScheduleData(updatedItems);
};

///

const getOneTwoThaliValue = (scheduleId) => {
  const selectedItem = Thalischeduledata.find(item => item.id === scheduleId);
  return selectedItem ? selectedItem.one_two_count : '';
};

const handleOneTwoThaliChange = (e, scheduleId) => {
  const newCount = e.target.value;
  updateOneTwoThaliValue(scheduleId, newCount);
};


const updateOneTwoThaliValue = (scheduleId, value) => {
  const updatedItems = Thalischeduledata.map(item => {
      if (item.id === scheduleId) {
          return {
              ...item,
              one_two_count: value
          };
      }
      return item;
  });
  setThaliScheduleData(updatedItems);
};

const getThreeFourThaliValue = (scheduleId) => {
  const selectedItem = Thalischeduledata.find(item => item.id === scheduleId);
  return selectedItem ? selectedItem.three_four_count : '';
};

const handleThreeFourThaliChange = (e, scheduleId) => {
  const newCount = e.target.value;
  updateThreeFourThaliValue(scheduleId, newCount);
};


const updateThreeFourThaliValue = (scheduleId, value) => {
  const updatedItems = Thalischeduledata.map(item => {
      if (item.id === scheduleId) {
          return {
              ...item,
              three_four_count: value
          };
      }
      return item;
  });
  setThaliScheduleData(updatedItems);
};

const getFiveSixThaliValue = (scheduleId) => {
  const selectedItem = Thalischeduledata.find(item => item.id === scheduleId);
  return selectedItem ? selectedItem.five_six_count : '';
};

const handleFiveSixThaliChange = (e, scheduleId) => {
  const newCount = e.target.value;
  updateFiveSixThaliValue(scheduleId, newCount);
};


const updateFiveSixThaliValue = (scheduleId, value) => {
  const updatedItems = Thalischeduledata.map(item => {
      if (item.id === scheduleId) {
          return {
              ...item,
              five_six_count: value
          };
      }
      return item;
  });
  setThaliScheduleData(updatedItems);
};



///

const getDishtypedata = () => {
  dispatch(getDishType()).then((res) => {
    if (res?.payload?.status === true) {
      setDishTypeList(res?.payload?.data);
      // console.log('',res.payload.data)
    } else {
      toast.error(res?.payload?.message);
    }
  });
};


const getCatererData = () => {
  dispatch(getAllcaterer()).then((res) => {
    if (res?.payload?.status == true) {
      setCatererlist(res?.payload?.data);
    } else {
      toast.error(res?.payload?.message);
    }
  });
};

const getnewdishlist = () => {
  dispatch(dishtypelistall()).then((res) => {
    if (res?.payload?.status == true) {
      setNewDishList(res?.payload?.data);
    } else {
      toast.error(res?.payload?.message);
    }
  });
};

const DishNameType = (id, niyaazType) => {


  const queryParams = new URLSearchParams();
  queryParams.append('dish_type', id);
  queryParams.append('niyaaz_type', JSON.stringify(niyaazType));

  const url = `${queryParams.toString()}`;

  dispatch(getDishNameView(url)).then((res) => {
    if (res?.payload === res?.payload) {

      console.log("res?.payload?.data", res?.payload?.data.repetition_count);
      setNameDishType(res?.payload?.data)
      setRepetitionCount(res?.payload?.data[0]?.repetition_count)
      setoptiondata({
        value: res?.payload?.data[0]?.dish_type,
        label: res?.payload?.data[0]?.dish_type
      })
    } else {
      // Handle the error here
    }
  });
};

let dishTypeListData = [];
if (Array.isArray(dishtypelist)) {
  dishTypeListData = dishtypelist.map((item) => ({
    label: item.dish_type,
    value: item.id,
  }));
} else {
  console.error("dishtypelist is not an array or is undefined");
}

let dishNameListall = [];
if (Array.isArray(dishNamelist)) {
  dishNameListall = dishNamelist.map((item) => ({
    label: item.dish_name,
    value: item.id,
  }));
} else {
  console.error("dishtypelist is not an array or is undefined");
}

const getThaliData = () => {
  setLoading(true);
  dispatch(getThalilist()).then((res) => {
    if (res?.payload?.status == true) {
      setThalidata(res?.payload?.data);
    } else {
      toast.error(res?.payload?.message);
    }
  })
    .finally(() => {
      setLoading(false); // Set loading to false when data fetching is complete
    });
};

const handleEventSubmit = (newEvent) => {
  setEvents([...events, newEvent]);
  setShowPopup(false);
};

let getnewlistdata;
if (newdishlist && Array.isArray(newdishlist)) {
  getnewlistdata = newdishlist?.map((item) => ({
    label: item?.dish_name,
    value: item?.id,
  }));
} else {
  getnewlistdata = [];
}

const handleDishChange = (event) => {
  setSelectedDish(event.target.value);
};

const handleDuplicate = (e) => {
  // setDuplicateCount(duplicateCount + 1);

  const newDishNum = [...storedDishes, storedDishes?.length + 1];
  setStoredDishes(newDishNum);
  console.log('newDishNumnewDishNum', newDishNum)

  setAddDish(false);
  // setoptiondata(e);
  setDishId(e.value);
  // setSelectedDish(e.target.value);
  setStoredDishes([...storedDishes, { optiondata, dishid, selecteddish }]);
  setDishEditdata([...disheditdata, { optiondata, dishid, selecteddish }]);
  // dishlistdatatype()
  reset();
  getDishnamedata();
};

const handleTotalCountChange = (e, scheduleId) => {
  const newTotalCount = e.target.value;
  setInputValues(prevState => ({
      ...prevState,
      [scheduleId]: newTotalCount,
  }));

  const requestBody = {
      schedule_id: scheduleId,
      thaal_count: newTotalCount,
  };

  fetch(fmb_schedule_url + 'admin/update-thaali-count', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('access_token'),
      },
      body: JSON.stringify(requestBody),
  })
  .then(response => response.json())
  .then(data => {
      if (data?.status === true) {
          console.log('Data list ', data?.data);
          // Handle success
      } else {
          // Handle error
          console.log('error ', data);
      }
  })
  .catch(error => {
      console.error(error);
  });
};


const handleTotalMainCourseCountChange = (e, scheduleId) => {
  const newTotalCount = e.target.value;
  setInputValues(prevState => ({
      ...prevState,
      [scheduleId]: newTotalCount,
  }));

  const requestBody = {
      schedule_id: scheduleId,
      thaal_main_course_count: newTotalCount,
  };

  fetch(fmb_schedule_url + 'admin/update-thaali-count', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('access_token'),
      },
      body: JSON.stringify(requestBody),
  })
  .then(response => response.json())
  .then(data => {
      if (data?.status === true) {
          console.log('Data list ', data?.data);
          // Handle success
      } else {
          // Handle error
          console.log('error ', data);
      }
  })
  .catch(error => {
      console.error(error);
  });
};


const handleTotalOneTwoChange = (e, scheduleId) => {
  const newTotalCount = e.target.value;
  setInputValues(prevState => ({
      ...prevState,
      [scheduleId]: newTotalCount,
  }));

  const requestBody = {
      schedule_id: scheduleId,
      one_two_count: newTotalCount,
  };

  fetch(fmb_schedule_url + 'admin/update-miqaat-thaali-count', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('access_token'),
      },
      body: JSON.stringify(requestBody),
  })
  .then(response => response.json())
  .then(data => {
      if (data?.status === true) {
          console.log('Data list ', data?.data);
          // Handle success
      } else {
          // Handle error
          console.log('error ', data);
      }
  })
  .catch(error => {
      console.error(error);
  });
};


const handleTotalThreeFourChange = (e, scheduleId) => {
  const newTotalCount = e.target.value;
  setInputValues(prevState => ({
      ...prevState,
      [scheduleId]: newTotalCount,
  }));

  const requestBody = {
      schedule_id: scheduleId,
      three_four_count: newTotalCount,
  };

  fetch(fmb_schedule_url + 'admin/update-miqaat-thaali-count', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('access_token'),
      },
      body: JSON.stringify(requestBody),
  })
  .then(response => response.json())
  .then(data => {
      if (data?.status === true) {
          console.log('Data list ', data?.data);
          // Handle success
      } else {
          // Handle error
          console.log('error ', data);
      }
  })
  .catch(error => {
      console.error(error);
  });
};

const handleTotalFiveSixChange = (e, scheduleId) => {
  const newTotalCount = e.target.value;
  setInputValues(prevState => ({
      ...prevState,
      [scheduleId]: newTotalCount,
  }));

  const requestBody = {
      schedule_id: scheduleId,
      five_six_count: newTotalCount,
  };

  fetch(fmb_schedule_url + 'admin/update-miqaat-thaali-count', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('access_token'),
      },
      body: JSON.stringify(requestBody),
  })
  .then(response => response.json())
  .then(data => {
      if (data?.status === true) {
          console.log('Data list ', data?.data);
          // Handle success
      } else {
          // Handle error
          console.log('error ', data);
      }
  })
  .catch(error => {
      console.error(error);
  });
};

const handleEditRemove = (index) => {
  setDuplicateCount(duplicateCount - 1);
  const updatedDishes = [...disheditdata];
  updatedDishes.splice(index, 1);
  setDishEditdata(updatedDishes);
};

const handleAllowSkipRiceToggleChange = (e) => {
  const newValue = allowSkipRice === "1" ? "0" : "1";
  setAllowSkipRice(newValue);
  console.log("newValue", newValue)
}

const handleAllowSkipRotiToggleChange = (e) => {
  const newValue = allowSkipRoti === "1" ? "0" : "1";
  setAllowSkipRoti(newValue);
  console.log("newValue3", newValue)
}

const Resetfild = (e) => {
  e.preventDefault();
  setDishTypeId(null)
  setFatehaName("")
}

const handleRadioButtonChange2 = (e, index) => {
  const { value } = e.target;
  const updatedDishes = disheditdata.map((item, i) => {
    if (i === index) {
      return {
        ...item,
        quantity: value,
      };
    }
    return item;
  });
  const updatedDishes2 = disheditdata.map((item, i) => {
    if (i === index) {
      return {
        ...item,
        selecteddish: value,
      };
    }
    return item;
  });

  console.log("updatedDishes2", updatedDishes2);
  setDishEditdata(updatedDishes);
};

const DishTypeName = (id, niyaazType) => {

  const queryParams = new URLSearchParams();
  queryParams.append('niyaaz_type', JSON.stringify(niyaazType));
  queryParams.append('dish_type', id);


  const url = `${queryParams.toString()}`;
  dispatch(getDishTypeView(url)).then((res) => {
    if (res?.payload === res?.payload) {
      // toast.success(res.payload.message);
      console.log('res', res)
      setDishNameListData(res?.payload?.data);

    } else {
      // toast.error(res.payload.message);
    }
  });
};

const getDishnamedata = () => {
  dispatch(getDishName(niyaazdish)).then((res) => {
    if (res?.payload?.status === true) {
      setDishNameListData(res?.payload?.data);
    } else {
      toast.error(res?.payload?.message);
    }
  });
};

const handleDishIdChange2 = (index, e) => {
  const updatedDishes = disheditdata.map((item, i) => {
    if (i === index) {
      return {
        ...item,
        dish_name: e.label,
        dish_id: e.value
      };
    }
    return item;
  });

  setDishEditdata(updatedDishes);
  getDishnamedata();

};

const reset = () => {
  setoptiondata(null);
  setDishId(null);
};

function AddDishModal() {
  setAddDish(true);
  setRepetitionCount("0")
}
function DishcloseModal() {
  setAddDish(false);
  setRepetitionCount("0")
  reset();
}

const handleRadioChange = (event) => {
  setSelectedOption(event.target.value);
  setIsNiyaaz ('0')
};

const handleRadioNiyaazChange = (event) => {
  setSelectedOption(event.target.value);
  setIsNiyaaz ('1')
};

const handleTabClick = (event) => {
  setDivVisible(!isDivVisible);
  setJamanType(event.target.value);
};

let dishviewlistdata = [];
if (Array.isArray(dishnamelistdata)) {
  dishviewlistdata = dishnamelistdata?.map((item) => ({
    label: item.dish_name,
    value: item.id,
    repetitioncountlist: item.repetition_count,
  }));
} else {
  console.error("dishtypelist is not an array or is undefined new");
}


function getNextThursday(selectedDate) {
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const dayOfWeek = weekdays[selectedDate.getDay()];

  const daysUntilThursday = (4 - weekdays.indexOf(dayOfWeek) + 7) % 7;
  const nextThursdayDate = new Date(selectedDate);
  nextThursdayDate.setDate(selectedDate.getDate() + daysUntilThursday);

  const formattedNextThursday = moment(nextThursdayDate).format('MM/DD/YYYY');
  return formattedNextThursday; // Return the date in DD/MM/YYYY format
}

const getthaliId = (id) => {
  dispatch(getThaliview(id)).then((res) => {
    if (res?.payload?.status === true) {
      setGetThaliDataAll(res?.payload);
      thalilistviewdata(res?.payload)
      setDishEditdata(res?.payload.dish)
      console.log('sdsdsd')
    } else {
      toast.error(res?.payload?.message);
    }
  });
}

const thalilistviewdata = (Thalidatalist) => {
  const alldishlist = Thalidatalist.dish;
  const allthalilist = Thalidatalist.thali;
  console.log('dataaaaaa', allthalilist.schedule_type)
  // const setoptionthali = allthalilist.map((allthalilist) => allthalilist.schedule_type);
  setSelectedOption(allthalilist.schedule_type)
  setJamanType(allthalilist.jamanType)
  allthalilist.jamanType === 'COOKING' ? setDivVisible (false) : setDivVisible (true)
  setCatererfirst(allthalilist.caterer_id1)
  setCaterersecond(allthalilist.caterer_id2)
  setFatehaName(allthalilist.fateha_name)
  setAllowSkipRice(allthalilist?.allow_skip_rice)
  setAllowSkipRoti(allthalilist?.allow_skip_roti)
  setOrder(allthalilist.order_by)
  setAllDateList(allthalilist.rsvp_date)
  setEndTime(allthalilist.rsvp_time)
  setDishId(allthalilist.id)
  setSaveDraft(allthalilist.save_draft)
  setIsNiyaaz(allthalilist.is_niyaaz)
  setDishTypeId(allthalilist.dish_id)
  setViswthali(allthalilist.id)
  setComment(allthalilist.notes)
  const dish_name = alldishlist.map((alldishlist) => alldishlist.dish_name);
  setDishNameData(dish_name);

}

// const getThaliData = () => {
//   setLoading(true);
//   dispatch(getThalilist()).then((res) => {
//     if (res?.payload?.status == true) {
//       setThalidata(res?.payload?.data);
//     } else {
//       toast.error(res?.payload?.message);
//     }
//   })
//     .finally(() => {
//       setLoading(false); // Set loading to false when data fetching is complete
//     });
// };


let getCatererlistdata;
if (catererlist && Array.isArray(catererlist)) {
  getCatererlistdata = catererlist?.map((item) => ({
    label: `${item?.first_name} ${item?.last_name ? item?.last_name : '-'}`,
    value: item?.id,
  }));
} else {
  getCatererlistdata = [];
}


const timezone = [
  { value: "1", label: "10.00 AM" },
  { value: "2", label: "11.00 AM" },
  { value: "3", label: "12.00 AM" },
  { value: "5", label: "01.00 PM" },
  { value: "6", label: "02.00 PM" },
  { value: "7", label: "03.00 PM" },
  { value: "8", label: "04.00 PM" },
  { value: "9", label: "05.00 PM" },
  { value: "10", label: "06.00 PM" },
  { value: "11", label: "07.00 PM" },
  { value: "12", label: "08.00 PM" },
  { value: "13", label: "09.00 PM" },
  { value: "14", label: "10.00 PM" },
  { value: "15", label: "11.00 PM" },
  { value: "16", label: "12.00 PM" },
  { value: "17", label: "01.00 AM" },
  { value: "18", label: "02.00 AM" },
  { value: "19", label: "03.00 AM" },
  { value: "20", label: "04.00 AM" },
  { value: "21", label: "05.00 AM" },
  { value: "22", label: "06.00 AM" },
  { value: "23", label: "07.00 AM" },
  { value: "24", label: "08.00 AM" },
  { value: "4", label: "09.00 AM" },
];


const EditThali = (schedule_day, schedule_id) => {
  console.log ("schedule_day ",schedule_day, schedule_id)
  setSelectedMenuId(schedule_id)
  getthaliId(schedule_id)
  setSelectedDate(schedule_day)
  setEditShowPopup(true)
  setShowview(false)

};



const handleUpdateSubmit2 = (e, savedraftall) => {
  setLoading(true);
  e.preventDefault();
  const obj = disheditdata?.map((item) => ({
    dish_id: item?.dish_id ? item?.dish_id : item.dishid.value || '', // Use default value only if dish_name is null or undefined
    quantity: item?.quantity ? item?.quantity : item?.selecteddish || '', // Use default value only if quantity is null or undefined
    is_fateha: "false",
  }));

  const requestData = {
    schedule_type: selectedOption,
  };


  if (selectedOption === "FMBTHALI") {

    requestData.save_draft = savedraftall;
    requestData.is_niyaaz = is_niyaaz;
    requestData.jamanType = jamantype || jamantype; // Set default value as an empty string
    requestData.dish = obj  // Set default value as an empty array
    requestData.schedule_type = selectedOption;
    requestData.schedule_day = currentDate || currentDate; // Set default value as an empty string
    requestData.pickup_start_time = currentDate || currentDate; // Set default value as an empty string
    requestData.pickup_end_time = currentDate || currentDate; // Set default value as an empty string
    requestData.caterer_id1 = catererfist?.value || catererfist; // Set default value as an empty string
    requestData.caterer_id2 = caterersecond?.value || caterersecond; // Set default value as an empty string
    requestData.notes = comment || comment; // Set default value as an empty string
    requestData.order_by = order; // Set default value as an empty string
    requestData.source = "community";
    requestData.created_user = access_id;
    requestData.dish_id = dishtypeid?.value || dishtypeid;
    requestData.is_active = "1";
    requestData.portion_type_code = "1";
    requestData.pickup_location_code = "2";
    requestData.allow_skip_rice = allowSkipRice;
    requestData.allow_skip_roti = allowSkipRoti;
    requestData.quantity = selectedroti || selectedroti; // Set default value as an empty string
    requestData.fateha_name = fatehaname || fatehaname; // Set default value as an empty string
    requestData.rsvp_date = alldatelist;
    requestData.rsvp_time = endtime;
  } else if (selectedOption === "MIQAAT") {
    requestData.save_draft = savedraftall;
    requestData.is_niyaaz = is_niyaaz;
    requestData.jamanType = jamantype || jamantype; // Set default value as an empty string
    requestData.dish = obj  // Set default value as an empty array
    requestData.schedule_type = "MIQAAT";
    requestData.schedule_day = currentDate || currentDate; // Set default value as an empty string
    requestData.pickup_start_time = currentDate || currentDate; // Set default value as an empty string
    requestData.pickup_end_time = currentDate || currentDate; // Set default value as an empty string
    requestData.caterer_id1 = catererfist?.value || catererfist; // Set default value as an empty string
    requestData.caterer_id2 = caterersecond?.value || caterersecond; // Set default value as an empty string
    requestData.notes = comment || comment; // Set default value as an empty string
    requestData.order_by = order; // Set default value as an empty string
    requestData.source = "community";
    requestData.created_user = access_id;
    requestData.dish_id = dishtypeid?.value || dishtypeid;
    requestData.is_active = "1";
    requestData.portion_type_code = "1";
    requestData.pickup_location_code = "2";
    requestData.allow_skip_rice = allowSkipRice;
    requestData.allow_skip_roti = allowSkipRoti;
    requestData.quantity = selectedroti || selectedroti; // Set default value as an empty string
    requestData.fateha_name = fatehaname || fatehaname; // Set default value as an empty string
    requestData.rsvp_date = alldatelist;
    requestData.rsvp_time = endtime; // Set default value as an empty string
  } else if (selectedOption === "MIQAAT") {
    requestData.save_draft = savedraftall;
    requestData.is_niyaaz = is_niyaaz;
    requestData.jamanType = jamantype || jamantype; // Set default value as an empty string
    requestData.dish = obj  // Set default value as an empty array
    requestData.schedule_type = "MIQAAT";
    requestData.schedule_day = currentDate || currentDate; // Set default value as an empty string
    requestData.pickup_start_time = currentDate || currentDate; // Set default value as an empty string
    requestData.pickup_end_time = currentDate || currentDate; // Set default value as an empty string
    requestData.caterer_id1 = catererfist?.value || catererfist; // Set default value as an empty string
    requestData.caterer_id2 = caterersecond?.value || caterersecond; // Set default value as an empty string
    requestData.notes = comment || comment; // Set default value as an empty string
    requestData.order_by = order; // Set default value as an empty string
    requestData.source = "community";
    requestData.created_user = access_id;
    requestData.dish_id = dishtypeid?.value || dishtypeid;
    requestData.is_active = "1";
    requestData.portion_type_code = "1";
    requestData.pickup_location_code = "2";
    requestData.allow_skip_rice = "0";
    requestData.allow_skip_roti = "0";
    requestData.quantity = selectedroti || selectedroti; // Set default value as an empty string
    requestData.fateha_name = fatehaname || fatehaname; // Set default value as an empty string
    requestData.rsvp_date = alldatelist;
    requestData.rsvp_time = endtime;
  }

  dispatch(Updatethali({ id: Viswthali, values: requestData }))
    .then((res) => {
      // Handle the successful update
      if (res?.payload?.status === true) {
          toast.success(res?.payload.message);
          handleEventSubmit();
          setEditShowPopup(false);
          getThaliData();
          Thalischedulelist();
      } else{
        toast.error(res?.payload.message);
      }
    })
    .catch((error) => {
      // Handle the failed update
      toast.error(error.message);
    });

};

const getdeletethali = (id) => {
  dispatch(deletethali(id)).then((res) => {
    if (res?.payload?.status == true) {

      setShowview(false)
      // setNewDishList(res?.payload?.data);
      getThaliData();
      Thalischedulelist();
      setEditShowPopup(false);
      toast.success("Remove Thali successful");
        setIsRemoveOpen(false);

    } else {
      toast.error(res?.payload?.message);
    }
  });
};

function RemoveMenuModal() {
  setIsRemoveMenuOpen(true);
}

function RemoveMenuCloseModal() {
  setIsRemoveMenuOpen(false);
}

function RemoveMenuData() {
  getdeletethali(selectedMenuId)
  setIsRemoveMenuOpen(false);
}

useEffect(() => {
  getDishnamedata();
}, [selectedOption]);


/****** */

  return (
    <>
      <div className="thali_section">
        <div className="thili_div container-fluid new_css">
          <h1 className="title_heading">Thaali Schedule</h1>
          <PDFDownloadLink
            document={generatePDF()}
            fileName={zoneDataFileName}
            className=""
            id="modal_close_btn2"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                ""
              ) : (
                <>
                  {" "}
                  <button className="btn" ref={buttonRef}>
                  </button>
                  {/* {Autoclickbutton()} */}
                </>
              )
            }
          </PDFDownloadLink>

          <PDFDownloadLink
            document={LabelPDF()}
            fileName={labelDataFileName}
            className=""
            id="modal_close_btn3"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "L"
              ) : (
                <>
                  {" "}
                  <button className="btn" ref={LabelRef}>
                  </button>
                  {/* {Autoclickbutton()} */}
                </>
              )
            }
          </PDFDownloadLink>

          <PDFDownloadLink
            document={MiqaatLabelPDF()}
            fileName={miqaatLabelDataFileName}
            className=""
            id="modal_close_btn3"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "L"
              ) : (
                <>
                  {" "}
                  <button className="btn" ref={MiqaatLabelRef}>
                  </button>
                  {/* {Autoclickbutton()} */}
                </>
              )
            }
          </PDFDownloadLink>

          <PDFDownloadLink
            document={FatehaPDF()}
            fileName={fatehaDataFileName}
            className=""
            id="modal_close_btn3"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                ""
              ) : (
                <>
                  {" "}
                  <button className="btn" ref={fatehaRef}>
                  </button>
                  {/* {Autoclickbutton()} */}
                </>
              )
            }
          </PDFDownloadLink>

          <PDFDownloadLink
            document={generateINGREDIENTS()}
            fileName= {ingredientsFileName}
            className=""
            id="modal_close_btn4"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                ""
              ) : (
                <>
                  {" "}
                  <button className="btn" ref={ingredientRef}>
                  </button>
                  {/* {Autoclickbutton()} */}
                </>
              )
            }
          </PDFDownloadLink>

          <PDFDownloadLink
            document={generate100INGREDIENTS()}
            fileName= {ingredients100FileName}
            className=""
            id="modal_close_btn4"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                ""
              ) : (
                <>
                  {" "}
                  <button className="btn" ref={ingredient100Ref}>
                  </button>
                  {/* {Autoclickbutton()} */}
                </>
              )
            }
          </PDFDownloadLink>          
          <div className="New_section">
            <div className="Form_box">
              {/* <div className="Calender_div">
                <img src={calender} className="img-fluid" /> &nbsp; &nbsp;
                <DateRangePicker
                  initialSettings={{ startDate: startDate, endDate: endDate }}
                  // onDateChange={handleDateChange}
                  onApply={handleDateChange}
                >
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={startDate}
                  />
                </DateRangePicker>
              </div> */}
              <div className="calender-icon-div">
                <img src={calender} className="img-fluid" />

                <div className="Calender_div">
                  <DateRangePicker
                    initialSettings={{
                      startDate: startDate,
                      endDate: endDate,
                    }}
                    // onDateChange={handleDateChange}
                    onApply={handleDateChange}
                  >
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={startDate}
                    />
                  </DateRangePicker>
                </div>
              </div>
            </div>
              <span className="Line_div">|</span>
            <button className="btn thali_btn csvbtn">
                <CSVLink data={csvData} headers={csvHeaders} filename={"Thaali_Schedule.csv"}>
                   Export List
                </CSVLink>                
            </button>
          </div>
          {/* <div>
            <span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
            <span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
            <span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
            <span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
            <span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
            <span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
          </div> */}
          {/* <nav>
            <ul className="pagination">
              <li className={`page-item`}>
                <button
                  className="page-link"
                  onClick={() => handlePreviousPage()}
                >
                  Previous
                </button>
              </li>

                <li>
                <button
                  className="page-link"
                  onClick={() => handleCurrentPage()}
                >
                    --
                  </button>
                </li>

              <li
                className={`page-item`}
              >
                <button
                  className="page-link"
                  onClick={() => handleNextPage()}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav> */}
        </div>
        <div className="Thaali-reg">
          {loading ? (
            // Render a loading indicator while data is being fetched
            <div className="Loading-img">
              <img src={loadergif} className="" />
            </div>
          ) : (
            <div></div>
          )}
                                <nav>
            <ul className="pagination">
              <li className={`page-item`}>
                <button
                  className="page-link"
                  onClick={() => handlePreviousPage()}
                >
                  Previous
                </button>
              </li>

                <li>
                <button
                  className="page-link"
                  onClick={() => handleCurrentPage()}
                >
                    --
                  </button>
                </li>

              <li
                className={`page-item`}
              >
                <button
                  className="page-link"
                  onClick={() => handleNextPage()}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
          {isMobile() ? 
          <div>
            {Thalischeduledata?.map((item) => {
                // const date = new Date(item.schedule_day);
                const date = convertDateForIos(item.schedule_day);
                const datePart = date.toISOString().split("T")[0];
                const day = date.toLocaleString("en-US", { weekday: "long" });
                const month = date.toLocaleString("en-US", { month: "long" });
                const numericDate = date.getDate();
                const twoDigitYear = date.getFullYear();

                return (
                  <div
                    key={item.id}
                    className={`card ${
                      item.schedule_type === "MIQAAT"
                        ? "MIQAATTYPE"
                        : "FMBThaliTYPE"
                    }`}
                  >
                    <div className="Calendar_card spacemargin">
                      {" "}
                      <h6>{day}</h6>
                      <h1>{numericDate}</h1>
                      <p>
                        {month}, {twoDigitYear}
                      </p>
                    </div>
                    <div className="reg-menu-div spacediv spacemargin">
                      <h5 class="thali-heding">Menu</h5>
                      <ul className="thali_mobile_menu_list">
                        {item.save_draft == "1" && (
                          <span className="Draft_thali_event">
                            {item.save_draft == "1" ? "Draft" : ""}
                          </span>
                        )}
                        {item.schedule_type == "MIQAAT" && item.is_niyaaz != '1' && (
                          <span className="Draft_MIQAAT_event">
                            {item.schedule_type == "MIQAAT" ? "MIQAAT" : ""}
                          </span>
                        )}
                        {item.schedule_type == "MIQAAT" &&  item.is_niyaaz == "1" && (
                          <span className="Draft_NIYAAZ_event">
                            {item.schedule_type == "MIQAAT" && item.is_niyaaz == "1" ? "PRIVATE" : ""}
                          </span>
                        )}
                        {item.schedule_type == "FMBTHALI" && item.is_niyaaz == "1" && (
                          <span className="Draft_NIYAAZ_event">
                            {item.schedule_type == "FMBTHALI" && item.is_niyaaz == "1" ? "MIQAAT-THALI" : ""}
                          </span>
                        )}
                        {item.menu &&
                          JSON.parse(item.menu).map((menu, index) => (
                            <ul key={index}>
                              <Link >
                              <li
                                  onClick={() => handleNavigateDishPage(menu.split(':')[0].trim(), item.schedule_type)}
                                >
                                {menu ? menu : "-"}
                              </li>
                              </Link>
                            </ul>
                          ))}
                      </ul>
                    </div>
                    <div className="thali-sched-caterer-div spacediv spacemargin">
                      <h5 class="thali-heding">Caterer</h5>
                      {item.jamanType == "COOKING" &&
                      <ul className="thali_menu_caterer_list">
                        {item.cater_name1 && item.cater_name1 !== "null" && (
                          <li>
                            &nbsp;
                            {/* <img src={user_icon} className='img-fluid' /> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                d="M7 6.7373C6.0375 6.7373 5.25 6.43105 4.6375 5.81855C4.025 5.20605 3.71875 4.41855 3.71875 3.45605C3.71875 2.49355 4.025 1.70605 4.6375 1.09355C5.25 0.481055 6.0375 0.174805 7 0.174805C7.9625 0.174805 8.75313 0.481055 9.37188 1.09355C9.99063 1.70605 10.3 2.49355 10.3 3.45605C10.3 4.41855 9.99063 5.20605 9.37188 5.81855C8.75313 6.43105 7.9625 6.7373 7 6.7373ZM2.29375 13.7123C1.79375 13.7123 1.375 13.5404 1.0375 13.1967C0.7 12.8529 0.53125 12.4373 0.53125 11.9498V11.4436C0.53125 10.8936 0.671875 10.4092 0.953125 9.99043C1.23438 9.57168 1.60625 9.25605 2.06875 9.04356C2.93125 8.65606 3.76562 8.36543 4.57187 8.17168C5.37812 7.97793 6.1875 7.88105 7 7.88105C7.825 7.88105 8.6375 7.98105 9.4375 8.18105C10.2375 8.38105 11.0625 8.66856 11.9125 9.04356C12.3875 9.24356 12.7688 9.55293 13.0563 9.97168C13.3438 10.3904 13.4875 10.8811 13.4875 11.4436V11.9498C13.4875 12.4373 13.3156 12.8529 12.9719 13.1967C12.6281 13.5404 12.2063 13.7123 11.7062 13.7123H2.29375Z"
                                fill="#777391"
                              />
                            </svg>{" "}
                            &nbsp; &nbsp;
                            {item.cater_name1 ? item.cater_name1 : "-"}
                          </li>
                        )}
                        {item.cater1_email &&
                        item.cater1_email !== "null" && (
                          <>
                            <li>
                              <img
                                src={email_icon}
                                className="img-fluid"
                                width="22px"
                                height="22px"
                              />
                              &nbsp; 
                              {item.cater1_email
                                ? item.cater1_email
                                : "-"}
                            </li>
                          </>
                        )}
                        {item.cater1_phone_number &&
                        item.cater1_phone_number !== "null" && (
                          <>
                         <li>
                              <img
                                src={phone_icon}
                                className="img-fluid"
                                width="18px"
                                height="18px"
                              />
                              &nbsp;
                              {item.cater1_phone_number
                                ? item.cater1_phone_number
                                : "-"}
                            </li>
                          </>
                        )}
                        {item.cater_name2 && item.cater_name2 !== "null" && (
                          <li>
                            {/* <img src={user_icon} className='img-fluid' /> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                d="M7 6.7373C6.0375 6.7373 5.25 6.43105 4.6375 5.81855C4.025 5.20605 3.71875 4.41855 3.71875 3.45605C3.71875 2.49355 4.025 1.70605 4.6375 1.09355C5.25 0.481055 6.0375 0.174805 7 0.174805C7.9625 0.174805 8.75313 0.481055 9.37188 1.09355C9.99063 1.70605 10.3 2.49355 10.3 3.45605C10.3 4.41855 9.99063 5.20605 9.37188 5.81855C8.75313 6.43105 7.9625 6.7373 7 6.7373ZM2.29375 13.7123C1.79375 13.7123 1.375 13.5404 1.0375 13.1967C0.7 12.8529 0.53125 12.4373 0.53125 11.9498V11.4436C0.53125 10.8936 0.671875 10.4092 0.953125 9.99043C1.23438 9.57168 1.60625 9.25605 2.06875 9.04356C2.93125 8.65606 3.76562 8.36543 4.57187 8.17168C5.37812 7.97793 6.1875 7.88105 7 7.88105C7.825 7.88105 8.6375 7.98105 9.4375 8.18105C10.2375 8.38105 11.0625 8.66856 11.9125 9.04356C12.3875 9.24356 12.7688 9.55293 13.0563 9.97168C13.3438 10.3904 13.4875 10.8811 13.4875 11.4436V11.9498C13.4875 12.4373 13.3156 12.8529 12.9719 13.1967C12.6281 13.5404 12.2063 13.7123 11.7062 13.7123H2.29375Z"
                                fill="#777391"
                              />
                            </svg>{" "}
                            &nbsp;
                            {item.cater_name2 ? item.cater_name2 : "-"}
                          </li>
                        )}
                      </ul>
                     }
                     {item.jamanType == "ORDERING" &&
                      <ul className="thali_menu_caterer_list">
                          <li>
                            {item.jamanType == "ORDERING" && (
                          <span className="Draft_ordering_event">
                            {item.jamanType == "ORDERING" ? "ORDERING" : ""}
                          </span>
                        )}

                          </li>
                          <>
                            <li>
                              <img
                                src={ven}
                                className="img-fluid"
                                width="16px"
                                height="16px"
                              />
                              &nbsp; &nbsp; 
                              {item.order_by
                                ? item.order_by
                                : "-"}
                            </li>
                          </>
                      </ul>
                     }
                    </div>
                    <div className="reg-menu-div spacediv spacemargin">
                      <h5 class="thali-heding">Fateha</h5>
                      <ul className="thali_menu_list">
                        <li>
                          {" "}
                          {item.is_fateha_dish_name
                            ? item.is_fateha_dish_name
                            : ""}{" "}
                          &nbsp; {item.fateha_name ? item.fateha_name : "-"}{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="roti-div spacemargin">
                     <h5 class="thali-heding">Thaali/Thaal Count</h5>
                      { item.schedule_type === "FMBTHALI" && item.is_niyaaz == "0" &&
                      <Link>
                        <button
                          onClick={() => getthalicountId(item.schedule_day)}
                          className="Count_btn"
                        >
                          {" "}
                          {item.thaaliCount ? item.thaaliCount : "-"}{" "}
                          &nbsp;&nbsp; &nbsp;
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M2.08327 13.2333C1.72771 13.2333 1.41938 13.1028 1.15827 12.8417C0.897157 12.5806 0.766602 12.2722 0.766602 11.9167V2.08333C0.766602 1.71667 0.897157 1.40278 1.15827 1.14167C1.41938 0.880556 1.72771 0.75 2.08327 0.75H6.64994V2.08333H2.08327V11.9167H11.9166V7.35H13.2499V11.9167C13.2499 12.2722 13.1194 12.5806 12.8583 12.8417C12.5972 13.1028 12.2833 13.2333 11.9166 13.2333H2.08327ZM5.58327 9.35L4.6666 8.41667L10.9999 2.08333H7.64994V0.75H13.2499V6.35H11.9166V3.01667L5.58327 9.35Z"
                              fill="#957137"
                            />
                          </svg>
                        </button>
                      </Link>
                      }
                      { item.schedule_type === "MIQAAT" &&
                      <th scope="row">
                          <Link>
                              <button
                              className="Count_btn"
                              >
                              {" "}
                              {item.thaal_count ? item.thaal_count : "-"}{" "}
                              </button>
                          </Link>
                          {(user_Role.includes("ADM") || user_Role.includes("MDM")) &&
                          <ul className="thali_menu_list">
                              <li>
                              Thal/Count:
                                  <div className="Form_box Totalcount">
                                     
                                      <input
                                          type="Number"
                                          placeholder="Count"
                                          className="form-control"
                                          id="inputcount"
                                          min="0"
                                          onKeyDown={preventMinus}
                                          onWheel={() => document.activeElement.blur()}
                                          onBlur={(e) => handleTotalCountChange(e, item.id)}
                                          onChange={(e) => handleInputChange(e, item.id)}
                                          value={getInputValue(item.id)}

                                      />
                                  </div>
                              </li>
                              <li>
                              Rice/Gravy: 
                                  <div className="Form_box Totalcount">
                                      <input
                                          type="Number"
                                          placeholder="Count"
                                          className="form-control"
                                          id="inputcount"
                                          min="0"
                                          onKeyDown={preventMinus}
                                          onWheel={() => document.activeElement.blur()}
                                          onBlur={(e) => handleTotalMainCourseCountChange(e, item.id)}
                                          onChange={(e) => handleMainCourseInputChange(e, item.id)}
                                          value={getMainCourseInputValue(item.id)}

                                      />
                                  </div>
                              </li>
                          </ul>
                          }
                      </th>
                     }
                     { item.schedule_type === "FMBTHALI" && item.is_niyaaz == "1" &&
                      <th scope="row">
                          <Link>
                              <button
                              className="Count_btn"
                              >
                              {" "}
                              {Number(item.one_two_count * 0.5) + Number(item.three_four_count) + Number(item.five_six_count * 1.5) ? Number(item.one_two_count * 0.5) + Number(item.three_four_count) + Number(item.five_six_count * 1.5) : "-"}{" "}
                              </button>
                          </Link>
                          { (user_Role.includes("ADM") || user_Role.includes("MDM")) &&
                          <ul className="thali_menu_list">
                              <li>
                              1-2 People:
                                  <div className="Form_box Totalcount">
                                     
                                      <input
                                          type="Number"
                                          placeholder="Count"
                                          className="form-control"
                                          id="inputcount"
                                          min="0"
                                          onKeyDown={preventMinus}
                                          onWheel={() => document.activeElement.blur()}
                                          onBlur={(e) => handleTotalOneTwoChange(e, item.id)}
                                          onChange={(e) => handleOneTwoThaliChange(e, item.id)}
                                          value={getOneTwoThaliValue(item.id)}
                                      />
                                  </div>
                              </li>
                              <li>
                              3-4 People:
                                  <div className="Form_box Totalcount">
                                      <input
                                          type="Number"
                                          placeholder="Count"
                                          className="form-control"
                                          id="inputcount"
                                          min="0"
                                          onKeyDown={preventMinus}
                                          onWheel={() => document.activeElement.blur()}
                                          onBlur={(e) => handleTotalThreeFourChange(e, item.id)}
                                          onChange={(e) => handleThreeFourThaliChange(e, item.id)}
                                          value={getThreeFourThaliValue(item.id)}

                                      />
                                  </div>
                              </li>
                              <li>
                              5-6 People:
                                  <div className="Form_box Totalcount">
                                     
                                      <input
                                          type="Number"
                                          placeholder="Count"
                                          className="form-control"
                                          id="inputcount"
                                          min="0"
                                          onKeyDown={preventMinus}
                                          onWheel={() => document.activeElement.blur()}
                                          onBlur={(e) => handleTotalFiveSixChange(e, item.id)}
                                          onChange={(e) => handleFiveSixThaliChange(e, item.id)}
                                          value={getFiveSixThaliValue(item.id)}
                                      />
                                  </div>
                              </li>
                          </ul>
                          }
                      </th>
                     }
                    </div>
                    <div className="roti-div spacemargin">
                     <h5 class="thali-heding">Ingredients</h5>
                      {" "}
                      <Link>
                        <button
                          onClick={() =>
                            getIngredientsId(
                              item.ingredient_id_array,
                              item.id,
                              item.schedule_day,
                              item.schedule_type,
                              item.is_niyaaz,
                              item.ingredientCount
                            )
                          }
                          className="Count_btn"
                        >
                          {" "}
                          {item.ingredientCount
                            ? item.ingredientCount
                            : "NA"}{" "}
                          &nbsp;&nbsp; &nbsp;
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M2.08327 13.2333C1.72771 13.2333 1.41938 13.1028 1.15827 12.8417C0.897157 12.5806 0.766602 12.2722 0.766602 11.9167V2.08333C0.766602 1.71667 0.897157 1.40278 1.15827 1.14167C1.41938 0.880556 1.72771 0.75 2.08327 0.75H6.64994V2.08333H2.08327V11.9167H11.9166V7.35H13.2499V11.9167C13.2499 12.2722 13.1194 12.5806 12.8583 12.8417C12.5972 13.1028 12.2833 13.2333 11.9166 13.2333H2.08327ZM5.58327 9.35L4.6666 8.41667L10.9999 2.08333H7.64994V0.75H13.2499V6.35H11.9166V3.01667L5.58327 9.35Z"
                              fill="#957137"
                            />
                          </svg>
                        </button>
                      </Link>
                    </div>
                    <div className="roti-div spacemargin">
                      <h5 class="thali-heding">Remarks</h5>
                      <ul className="thali_menu_list">
                        <div>
                          <div style={{ fontSize: 14, width: "80px", fontWeight: '420', color: "#2E294E" }}>{item.notes ? item.notes : "-"}</div>
                        </div>
                        </ul>
                    </div>
                    <div className="roti-div spacemargin">
                      <h5 class="thali-heding">Actions</h5>
                      {" "}
                      <ul className="thali_menu_list">
                        <li>
                          <div className="dropdown action_btn">
                            <button
                              className="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton2"
                            >
                             <li
                                onClick={() => EditThali(item.schedule_day, item.id)}
                              >
                                <a
                                  className="dropdown-item"
                                >
                                  - Edit Test Menu
                                </a>
                              </li>
                              {item?.fateha_name && (
                                <li
                                  onClick={() =>
                                    getFatehaName(
                                      item?.is_fateha_dish_name,
                                      item?.fateha_name,
                                      item?.thaaliCount,
                                      item?.schedule_day
                                    )
                                  }
                                >
                                  <a
                                    className="dropdown-item"
                                  >
                                    - Fateha Print
                                  </a>
                                </li>
                              )}

                            {item?.schedule_type === "FMBTHALI" && (

                              <li
                                onClick={() => LabelTypeName(item.schedule_day)}
                              >
                                <a
                                  className="dropdown-item"
                                  // onClick={openModal}
                                >
                                  {/* <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="19"
                                              height="15"
                                              viewBox="0 0 19 15"
                                              fill="none"
                                            >
                                              <path
                                                d="M17.125 8.65L15.35 6.875L16.075 6.15C16.2135 6.01667 16.3898 5.95 16.6039 5.95C16.818 5.95 16.9917 6.01667 17.125 6.15L17.85 6.875C17.9833 7.01352 18.05 7.18982 18.05 7.4039C18.05 7.61797 17.9833 7.79167 17.85 7.925L17.125 8.65ZM9 15V13.225L14.275 7.95L16.05 9.725L10.775 15H9ZM0.75 9.75C0.335787 9.75 0 9.41421 0 9C0 8.58579 0.335786 8.25 0.75 8.25H6.75C7.16421 8.25 7.5 8.58579 7.5 9C7.5 9.41421 7.16421 9.75 6.75 9.75H0.75ZM0.75 5.625C0.335786 5.625 0 5.28921 0 4.875C0 4.46079 0.335786 4.125 0.75 4.125H11C11.4142 4.125 11.75 4.46079 11.75 4.875C11.75 5.28921 11.4142 5.625 11 5.625H0.75ZM0.75 1.5C0.335786 1.5 0 1.16421 0 0.75C0 0.335786 0.335786 0 0.75 0H11C11.4142 0 11.75 0.335786 11.75 0.75C11.75 1.16421 11.4142 1.5 11 1.5H0.75Z"
                                                fill="#777391"
                                              />
                                            </svg>{" "} */}
                                  - Labels Print
                                </a>
                              </li>
                              )}

                             {item?.schedule_type === "FMBTHALI" && (
                              <li
                                onClick={() => ZoneTypeName(item.schedule_day)}
                              >
                                <a
                                  className="dropdown-item"
                                >
                                  - Zone Sheet Print
                                </a>
                              </li>
                              )}
                              
                              {item?.schedule_type === "FMBTHALI" && (

                              <li
                                onClick={() =>
                                  getIngredientsdata(
                                    item.ingredient_id_array,
                                    item.id,
                                    item.schedule_day,
                                    item.schedule_type,
                                    item.ingredientCount,
                                    item.is_niyaaz,
                                    item.menu
                                  )
                                }
                              >
                                
                                <a
                                  className="dropdown-item"
                                >
                                  - Ingredients Print
                                </a>
                              </li>
                              )}

                              {item?.schedule_type === "FMBTHALI" && (

                              <li
                                onClick={() =>
                                  getIngredients100data(
                                    item.ingredient_id_array,
                                    item.id,
                                    item.schedule_day,
                                    item.schedule_type,
                                    item.ingredientCount,
                                    item.is_niyaaz,
                                    item.menu
                                  )
                                }
                              >
                                <a
                                  className="dropdown-item"
                                >
                                  - Ingredients 100 Thaalis Print
                                </a>
                              </li>
                               )}
                              {item?.schedule_type === "MIQAAT" && (

                                <li
                                onClick={() =>
                                  getIngredientsdata(
                                    item.ingredient_id_array,
                                    item.id,
                                    item.schedule_day,
                                    item.schedule_type,
                                    item.ingredientCount,
                                    item.is_niyaaz,
                                    item.menu
                                  )
                                }
                                >
                                  <a
                                    className="dropdown-item"
                                  >
                                    - Ingredients Print
                                  </a>
                                </li>
                                )}
                              {item?.schedule_type === "MIQAAT" && (
                              <li
                                  onClick={() =>
                                    getIngredients100data(
                                      item.ingredient_id_array,
                                      item.id,
                                      item.schedule_day,
                                      item.schedule_type,
                                      item.ingredientCount,
                                      item.is_niyaaz,
                                      item.menu
                                    )
                                  }
                              >
                                <a
                                  className="dropdown-item"
                                >
                                  - Ingredients 10 Thaals Print
                                </a>
                              </li>
                              )}
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
          </div>
          : 
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">
                  <span className="thali_table">Day & Date</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Menu</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Caterers</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Fateha</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Thaali / Thaal Count</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Ingredients</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Remarks</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {Thalischeduledata?.map((item) => {
                // const date = new Date(item.schedule_day);
                const date = convertDateForIos(item.schedule_day);
                const datePart = date.toISOString().split("T")[0];
                const day = date.toLocaleString("en-US", { weekday: "long" });
                const month = date.toLocaleString("en-US", { month: "long" });
                const numericDate = date.getDate();
                const twoDigitYear = date.getFullYear();

                return (
                  <tr
                    key={item.id}
                    className={`${
                      item.schedule_type === "MIQAAT"
                        ? "MIQAATTYPE"
                        : "FMBThaliTYPE"
                    }`}
                  >
                    <th scope="row">
                      <div className="Calendar_card">
                        {" "}
                        <h6>{day}</h6>
                        <h1>{numericDate}</h1>
                        <p>
                          {month}, {twoDigitYear}
                        </p>
                      </div>
                    </th>
                    <th scope="row">
                      <ul className="thali_menu_list">
                        {item.save_draft == "1" && (
                          <span className="Draft_thali_event">
                            {item.save_draft == "1" ? "Draft" : ""}
                          </span>
                        )}
                        {item.schedule_type == "MIQAAT" && item.is_niyaaz != '1' && (
                          <span className="Draft_MIQAAT_event">
                            {item.schedule_type == "MIQAAT" ? "MIQAAT" : ""}
                          </span>
                        )}
                        {item.schedule_type == "MIQAAT" && item.is_niyaaz == "1" && (
                          <span className="Draft_NIYAAZ_event">
                            {item.schedule_type == "MIQAAT" &&  item.is_niyaaz == "1" ? "PRIVATE" : ""}
                          </span>
                        )}
                        {item.schedule_type == "FMBTHALI" && item.is_niyaaz == "1" && (
                          <span className="Draft_NIYAAZ_event">
                            {item.schedule_type == "FMBTHALI" &&  item.is_niyaaz == "1" ? "MIQAAT-THALI" : ""}
                          </span>
                        )}
                        {item.menu &&
                          JSON.parse(item.menu).map((menu, index) => (
                            <li key={index}>
                              <Link >
                              <li
                                  onClick={() => handleNavigateDishPage(menu.split(':')[0].trim(), item.schedule_type)}
                                >
                                {menu ? menu : "-"}
                              </li>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </th>
                    <th scope="row">
                      {item.jamanType == "COOKING" &&
                      <ul className="thali_menu_caterer_list">
                        {item.cater_name1 && item.cater_name1 !== "null" && (
                          <li>
                            &nbsp;
                            {/* <img src={user_icon} className='img-fluid' /> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                d="M7 6.7373C6.0375 6.7373 5.25 6.43105 4.6375 5.81855C4.025 5.20605 3.71875 4.41855 3.71875 3.45605C3.71875 2.49355 4.025 1.70605 4.6375 1.09355C5.25 0.481055 6.0375 0.174805 7 0.174805C7.9625 0.174805 8.75313 0.481055 9.37188 1.09355C9.99063 1.70605 10.3 2.49355 10.3 3.45605C10.3 4.41855 9.99063 5.20605 9.37188 5.81855C8.75313 6.43105 7.9625 6.7373 7 6.7373ZM2.29375 13.7123C1.79375 13.7123 1.375 13.5404 1.0375 13.1967C0.7 12.8529 0.53125 12.4373 0.53125 11.9498V11.4436C0.53125 10.8936 0.671875 10.4092 0.953125 9.99043C1.23438 9.57168 1.60625 9.25605 2.06875 9.04356C2.93125 8.65606 3.76562 8.36543 4.57187 8.17168C5.37812 7.97793 6.1875 7.88105 7 7.88105C7.825 7.88105 8.6375 7.98105 9.4375 8.18105C10.2375 8.38105 11.0625 8.66856 11.9125 9.04356C12.3875 9.24356 12.7688 9.55293 13.0563 9.97168C13.3438 10.3904 13.4875 10.8811 13.4875 11.4436V11.9498C13.4875 12.4373 13.3156 12.8529 12.9719 13.1967C12.6281 13.5404 12.2063 13.7123 11.7062 13.7123H2.29375Z"
                                fill="#777391"
                              />
                            </svg>{" "}
                            &nbsp; &nbsp;
                            {item.cater_name1 ? item.cater_name1 : "-"}
                          </li>
                        )}
                        {item.cater1_email &&
                        item.cater1_email !== "null" && (
                          <>
                            <li>
                              <img
                                src={email_icon}
                                className="img-fluid"
                                width="22px"
                                height="22px"
                              />
                              &nbsp; 
                              {item.cater1_email
                                ? item.cater1_email
                                : "-"}
                            </li>
                          </>
                        )}
                        {item.cater1_phone_number &&
                        item.cater1_phone_number !== "null" && (
                          <>
                         <li>
                              <img
                                src={phone_icon}
                                className="img-fluid"
                                width="18px"
                                height="18px"
                              />
                              &nbsp;
                              {item.cater1_phone_number
                                ? item.cater1_phone_number
                                : "-"}
                            </li>
                          </>
                        )}
                        {item.cater_name2 && item.cater_name2 !== "null" && (
                          <li>
                            {/* <img src={user_icon} className='img-fluid' /> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                d="M7 6.7373C6.0375 6.7373 5.25 6.43105 4.6375 5.81855C4.025 5.20605 3.71875 4.41855 3.71875 3.45605C3.71875 2.49355 4.025 1.70605 4.6375 1.09355C5.25 0.481055 6.0375 0.174805 7 0.174805C7.9625 0.174805 8.75313 0.481055 9.37188 1.09355C9.99063 1.70605 10.3 2.49355 10.3 3.45605C10.3 4.41855 9.99063 5.20605 9.37188 5.81855C8.75313 6.43105 7.9625 6.7373 7 6.7373ZM2.29375 13.7123C1.79375 13.7123 1.375 13.5404 1.0375 13.1967C0.7 12.8529 0.53125 12.4373 0.53125 11.9498V11.4436C0.53125 10.8936 0.671875 10.4092 0.953125 9.99043C1.23438 9.57168 1.60625 9.25605 2.06875 9.04356C2.93125 8.65606 3.76562 8.36543 4.57187 8.17168C5.37812 7.97793 6.1875 7.88105 7 7.88105C7.825 7.88105 8.6375 7.98105 9.4375 8.18105C10.2375 8.38105 11.0625 8.66856 11.9125 9.04356C12.3875 9.24356 12.7688 9.55293 13.0563 9.97168C13.3438 10.3904 13.4875 10.8811 13.4875 11.4436V11.9498C13.4875 12.4373 13.3156 12.8529 12.9719 13.1967C12.6281 13.5404 12.2063 13.7123 11.7062 13.7123H2.29375Z"
                                fill="#777391"
                              />
                            </svg>{" "}
                            &nbsp;
                            {item.cater_name2 ? item.cater_name2 : "-"}
                          </li>
                        )}
                      </ul>
                     }
                     {item.jamanType == "ORDERING" &&
                      <ul className="thali_menu_caterer_list">
                          <li>
                            {item.jamanType == "ORDERING" && (
                          <span className="Draft_ordering_event">
                            {item.jamanType == "ORDERING" ? "ORDERING" : ""}
                          </span>
                        )}

                          </li>
                          <>
                            <li>
                              <img
                                src={ven}
                                className="img-fluid"
                                width="16px"
                                height="16px"
                              />
                              &nbsp; &nbsp; 
                              {item.order_by
                                ? item.order_by
                                : "-"}
                            </li>
                          </>
                      </ul>
                     }
                    </th>
                    <th scope="row">
                      <ul className="thali_menu_list">
                        <li>
                          {" "}
                          {item.is_fateha_dish_name
                            ? item.is_fateha_dish_name
                            : ""}{" "}
                          &nbsp; {item.fateha_name ? item.fateha_name : "-"}{" "}
                        </li>
                      </ul>
                    </th>
                    <th scope="row">
                    { item.schedule_type === "FMBTHALI" && item.is_niyaaz == "0" &&
                      <Link>
                        <button
                          onClick={() => getthalicountId(item.schedule_day)}
                          className="Count_btn"
                        >
                          {" "}
                          {item.thaaliCount ? item.thaaliCount : "-"}{" "}
                          &nbsp;&nbsp; &nbsp;
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M2.08327 13.2333C1.72771 13.2333 1.41938 13.1028 1.15827 12.8417C0.897157 12.5806 0.766602 12.2722 0.766602 11.9167V2.08333C0.766602 1.71667 0.897157 1.40278 1.15827 1.14167C1.41938 0.880556 1.72771 0.75 2.08327 0.75H6.64994V2.08333H2.08327V11.9167H11.9166V7.35H13.2499V11.9167C13.2499 12.2722 13.1194 12.5806 12.8583 12.8417C12.5972 13.1028 12.2833 13.2333 11.9166 13.2333H2.08327ZM5.58327 9.35L4.6666 8.41667L10.9999 2.08333H7.64994V0.75H13.2499V6.35H11.9166V3.01667L5.58327 9.35Z"
                              fill="#957137"
                            />
                          </svg>
                        </button>
                      </Link>
                      }
                      { item.schedule_type === "MIQAAT" &&
                      <th scope="row">
                          <Link>
                              <button
                              className="Count_btn"
                              >
                              {" "}
                              {item.thaal_count ? item.thaal_count : "-"}{" "}
                              </button>
                          </Link>
                          { (user_Role.includes("ADM") || user_Role.includes("MDM")) &&
                          <ul className="thali_menu_list">
                              <li>
                              Thal/Count:
                                  <div className="Form_box Totalcount">
                                     
                                      <input
                                          type="Number"
                                          placeholder="Count"
                                          className="form-control"
                                          id="inputcount"
                                          min="0"
                                          onKeyDown={preventMinus}
                                          onWheel={() => document.activeElement.blur()}
                                          onBlur={(e) => handleTotalCountChange(e, item.id)}
                                          onChange={(e) => handleInputChange(e, item.id)}
                                          value={getInputValue(item.id)}

                                      />
                                  </div>
                              </li>
                              <li>
                              Rice/Gravy: 
                                  <div className="Form_box Totalcount">
                                      <input
                                          type="Number"
                                          placeholder="Count"
                                          className="form-control"
                                          id="inputcount"
                                          min="0"
                                          onKeyDown={preventMinus}
                                          onWheel={() => document.activeElement.blur()}
                                          onBlur={(e) => handleTotalMainCourseCountChange(e, item.id)}
                                          onChange={(e) => handleMainCourseInputChange(e, item.id)}
                                          value={getMainCourseInputValue(item.id)}

                                      />
                                  </div>
                              </li>
                          </ul>
                          }
                      </th>
                     }
                     { item.schedule_type === "FMBTHALI" && item.is_niyaaz == "1" &&
                      <th scope="row">
                          <Link>
                              <button
                              className="Count_btn"
                              >
                              {" "}
                              {Number(item.one_two_count * 0.5) + Number(item.three_four_count) + Number(item.five_six_count * 1.5) ? Number(item.one_two_count * 0.5) + Number(item.three_four_count) + Number(item.five_six_count * 1.5) : "-"}{" "}
                              {/* {Number(item.one_two_count * 0.5) + Number(item.three_four_count * 1) ? Number(item.one_two_count * 0.5) + Number(item.three_four_count * 1) : "-"}{" "} */}
                              </button>
                          </Link>
                          { (user_Role.includes("ADM") || user_Role.includes("MDM")) &&
                          <ul className="thali_menu_list">
                              <li>
                              1-2 People:
                                  <div className="Form_box Totalcount">
                                     
                                      <input
                                          type="Number"
                                          placeholder="Count"
                                          className="form-control"
                                          id="inputcount"
                                          min="0"
                                          onKeyDown={preventMinus}
                                          onWheel={() => document.activeElement.blur()}
                                          onBlur={(e) => handleTotalOneTwoChange(e, item.id)}
                                          onChange={(e) => handleOneTwoThaliChange(e, item.id)}
                                          value={getOneTwoThaliValue(item.id)}
                                      />
                                  </div>
                              </li>
                              <li>
                              3-4 People:
                                  <div className="Form_box Totalcount">
                                      <input
                                          type="Number"
                                          placeholder="Count"
                                          className="form-control"
                                          id="inputcount"
                                          min="0"
                                          onKeyDown={preventMinus}
                                          onWheel={() => document.activeElement.blur()}
                                          onBlur={(e) => handleTotalThreeFourChange(e, item.id)}
                                          onChange={(e) => handleThreeFourThaliChange(e, item.id)}
                                          value={getThreeFourThaliValue(item.id)}

                                      />
                                  </div>
                              </li>
                              <li>
                              5-6 People:
                                  <div className="Form_box Totalcount">
                                     
                                      <input
                                          type="Number"
                                          placeholder="Count"
                                          className="form-control"
                                          id="inputcount"
                                          min="0"
                                          onKeyDown={preventMinus}
                                          onWheel={() => document.activeElement.blur()}
                                          onBlur={(e) => handleTotalFiveSixChange(e, item.id)}
                                          onChange={(e) => handleFiveSixThaliChange(e, item.id)}
                                          value={getFiveSixThaliValue(item.id)}
                                      />
                                  </div>
                              </li>
                          </ul>
                          }
                      </th>
                     }
                    </th>
                    <th scope="row">
                      {" "}
                      <Link>
                        <button
                          onClick={() =>
                            getIngredientsId(
                              item.ingredient_id_array,
                              item.id,
                              item.schedule_day,
                              item.schedule_type,
                              item.is_niyaaz,
                              item.ingredientCount
                            )
                          }
                          className="Count_btn"
                        >
                          {" "}
                          {item.ingredientCount
                            ? item.ingredientCount
                            : "NA"}{" "}
                          &nbsp;&nbsp; &nbsp;
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M2.08327 13.2333C1.72771 13.2333 1.41938 13.1028 1.15827 12.8417C0.897157 12.5806 0.766602 12.2722 0.766602 11.9167V2.08333C0.766602 1.71667 0.897157 1.40278 1.15827 1.14167C1.41938 0.880556 1.72771 0.75 2.08327 0.75H6.64994V2.08333H2.08327V11.9167H11.9166V7.35H13.2499V11.9167C13.2499 12.2722 13.1194 12.5806 12.8583 12.8417C12.5972 13.1028 12.2833 13.2333 11.9166 13.2333H2.08327ZM5.58327 9.35L4.6666 8.41667L10.9999 2.08333H7.64994V0.75H13.2499V6.35H11.9166V3.01667L5.58327 9.35Z"
                              fill="#957137"
                            />
                          </svg>
                        </button>
                      </Link>
                    </th>
                    <th scope="row">
                    <ul className="thali_menu_list">
                      <div>
                        <div style={{ fontSize: 14, width: "80px", fontWeight: '420', color: "#2E294E" }}>{item.notes ? item.notes : "-"}</div>
                      </div>
                      </ul>
                    </th>
                    <th scope="row">
                      {" "}
                      <ul className="thali_menu_list">
                        <li>
                          <div className="dropdown action_btn">
                            <button
                              className="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton2"
                            >
                            { (user_Role.includes("ADM") || user_Role.includes("OPT") || user_Role.includes("ODM") || user_Role.includes("MIQ") || user_Role.includes("MDM")) &&
                             <li
                                onClick={() => EditThali(item.schedule_day, item.id)}
                              >
                                <a
                                  className="dropdown-item"
                                >
                                  - Edit Menu
                                </a>
                              </li>
                              }
                              {item?.fateha_name && (
                                <li
                                  onClick={() =>
                                    getFatehaName(
                                      item?.is_fateha_dish_name,
                                      item?.fateha_name,
                                      item?.thaaliCount,
                                      item?.schedule_day
                                    )
                                  }
                                >
                                  <a
                                    className="dropdown-item"
                                  >
                                    - Fateha Print
                                  </a>
                                </li>
                              )}

                            {(item?.schedule_type === "FMBTHALI" && item?.is_niyaaz === 0) && (

                              <li
                                onClick={() => LabelTypeName(item.schedule_day)}
                              >
                                <a
                                  className="dropdown-item"
                                  // onClick={openModal}
                                >
                                  {/* <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="19"
                                              height="15"
                                              viewBox="0 0 19 15"
                                              fill="none"
                                            >
                                              <path
                                                d="M17.125 8.65L15.35 6.875L16.075 6.15C16.2135 6.01667 16.3898 5.95 16.6039 5.95C16.818 5.95 16.9917 6.01667 17.125 6.15L17.85 6.875C17.9833 7.01352 18.05 7.18982 18.05 7.4039C18.05 7.61797 17.9833 7.79167 17.85 7.925L17.125 8.65ZM9 15V13.225L14.275 7.95L16.05 9.725L10.775 15H9ZM0.75 9.75C0.335787 9.75 0 9.41421 0 9C0 8.58579 0.335786 8.25 0.75 8.25H6.75C7.16421 8.25 7.5 8.58579 7.5 9C7.5 9.41421 7.16421 9.75 6.75 9.75H0.75ZM0.75 5.625C0.335786 5.625 0 5.28921 0 4.875C0 4.46079 0.335786 4.125 0.75 4.125H11C11.4142 4.125 11.75 4.46079 11.75 4.875C11.75 5.28921 11.4142 5.625 11 5.625H0.75ZM0.75 1.5C0.335786 1.5 0 1.16421 0 0.75C0 0.335786 0.335786 0 0.75 0H11C11.4142 0 11.75 0.335786 11.75 0.75C11.75 1.16421 11.4142 1.5 11 1.5H0.75Z"
                                                fill="#777391"
                                              />
                                            </svg>{" "} */}
                                  - Labels Print
                                </a>
                              </li>
                              )}

                              {(item?.schedule_type === "FMBTHALI" && item?.is_niyaaz === 1) && (

                              <li
                                onClick={() => MiqaatLabelTypeName(item.schedule_day)}
                              >
                                <a
                                  className="dropdown-item"
                                  // onClick={openModal}
                                >
                                  {/* <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="19"
                                              height="15"
                                              viewBox="0 0 19 15"
                                              fill="none"
                                            >
                                              <path
                                                d="M17.125 8.65L15.35 6.875L16.075 6.15C16.2135 6.01667 16.3898 5.95 16.6039 5.95C16.818 5.95 16.9917 6.01667 17.125 6.15L17.85 6.875C17.9833 7.01352 18.05 7.18982 18.05 7.4039C18.05 7.61797 17.9833 7.79167 17.85 7.925L17.125 8.65ZM9 15V13.225L14.275 7.95L16.05 9.725L10.775 15H9ZM0.75 9.75C0.335787 9.75 0 9.41421 0 9C0 8.58579 0.335786 8.25 0.75 8.25H6.75C7.16421 8.25 7.5 8.58579 7.5 9C7.5 9.41421 7.16421 9.75 6.75 9.75H0.75ZM0.75 5.625C0.335786 5.625 0 5.28921 0 4.875C0 4.46079 0.335786 4.125 0.75 4.125H11C11.4142 4.125 11.75 4.46079 11.75 4.875C11.75 5.28921 11.4142 5.625 11 5.625H0.75ZM0.75 1.5C0.335786 1.5 0 1.16421 0 0.75C0 0.335786 0.335786 0 0.75 0H11C11.4142 0 11.75 0.335786 11.75 0.75C11.75 1.16421 11.4142 1.5 11 1.5H0.75Z"
                                                fill="#777391"
                                              />
                                            </svg>{" "} */}
                                  - Miqaat Labels Print
                                </a>
                              </li>
                              )}
                              

                             {item?.schedule_type === "FMBTHALI" && (
                              <li
                                onClick={() => ZoneTypeName(item.schedule_day)}
                              >
                                <a
                                  className="dropdown-item"
                                >
                                  - Zone Sheet Print
                                </a>
                              </li>
                              )}
                              
                              {item?.schedule_type === "FMBTHALI" && (

                              <li
                                onClick={() =>
                                  getIngredientsdata(
                                    item.ingredient_id_array,
                                    item.id,
                                    item.schedule_day,
                                    item.schedule_type,
                                    item.ingredientCount,
                                    item.is_niyaaz,
                                    item.menu
                                  )
                                }
                              >
                                
                                <a
                                  className="dropdown-item"
                                >
                                  - Ingredients Print
                                </a>
                              </li>
                              )}

                              {item?.schedule_type === "FMBTHALI" && (

                              <li
                                onClick={() =>
                                  getIngredients100data(
                                    item.ingredient_id_array,
                                    item.id,
                                    item.schedule_day,
                                    item.schedule_type,
                                    item.ingredientCount,
                                    item.is_niyaaz,
                                    item.menu
                                  )
                                }
                              >
                                <a
                                  className="dropdown-item"
                                >
                                  - Ingredients 100 Thaalis Print
                                </a>
                              </li>
                               )}
                              {item?.schedule_type === "MIQAAT" && (

                                <li
                                onClick={() =>
                                  getIngredientsdata(
                                    item.ingredient_id_array,
                                    item.id,
                                    item.schedule_day,
                                    item.schedule_type,
                                    item.ingredientCount,
                                    item.is_niyaaz,
                                    item.menu
                                  )
                                }
                                >
                                  <a
                                    className="dropdown-item"
                                  >
                                    - Ingredients Print
                                  </a>
                                </li>
                                )}
                              {item?.schedule_type === "MIQAAT" && (
                              <li
                                  onClick={() =>
                                    getIngredients100data(
                                      item.ingredient_id_array,
                                      item.id,
                                      item.schedule_day,
                                      item.schedule_type,
                                      item.ingredientCount,
                                      item.is_niyaaz,
                                      item.menu
                                    )
                                  }
                              >
                                <a
                                  className="dropdown-item"
                                >
                                  - Ingredients 10 Thaals Print
                                </a>
                              </li>
                              )}
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
          }
          {/* <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">
                  <span className="thali_table">Day & Date</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Menu</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Caterers</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Fateha</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Thaali / Thaal Count</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Ingredients</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Remarks</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Actions</span>
                </th>
              </tr>
            </thead> */}
              
          {/* </table> */}
        </div>

        <nav>
          <ul className="pagination">
            <li className={`page-item`}>
              <button
                className="page-link"
                onClick={() => handlePreviousPage()}
              >
                Previous
              </button>
            </li>

              <li>
              <button
                className="page-link"
                onClick={() => handleCurrentPage()}
              >
                  --
                </button>
              </li>

            <li
              className={`page-item`}
            >
              <button
                className="page-link"
                onClick={() => handleNextPage()}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>

      <Modal
        isOpen={RemoveIsOpen}
        onAfterOpen={RemoveOpenModal}
        onRequestClose={RemovecloseModal}
        style={customModal}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        <div className="popup_modal event-div">
          <button onClick={RemovecloseModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M6.99967 8.19364L1.82142 13.3719C1.65149 13.5418 1.45257 13.6268 1.22467 13.6268C0.996772 13.6268 0.797855 13.5418 0.627922 13.3719C0.458005 13.202 0.373047 13.0031 0.373047 12.7752C0.373047 12.5473 0.458005 12.3483 0.627922 12.1784L5.8062 7.00016L0.627922 1.82191C0.458005 1.65198 0.373047 1.45306 0.373047 1.22516C0.373047 0.99726 0.458005 0.798344 0.627922 0.62841C0.797855 0.458494 0.996772 0.373535 1.22467 0.373535C1.45257 0.373535 1.65149 0.458494 1.82142 0.62841L6.99967 5.80668L12.1779 0.62841C12.3479 0.458494 12.5468 0.373535 12.7747 0.373535C13.0026 0.373535 13.2015 0.458494 13.3714 0.62841C13.5413 0.798344 13.6263 0.99726 13.6263 1.22516C13.6263 1.45306 13.5413 1.65198 13.3714 1.82191L8.19315 7.00016L13.3714 12.1784C13.5413 12.3483 13.6263 12.5473 13.6263 12.7752C13.6263 13.0031 13.5413 13.202 13.3714 13.3719C13.2015 13.5418 13.0026 13.6268 12.7747 13.6268C12.5468 13.6268 12.3479 13.5418 12.1779 13.3719L6.99967 8.19364Z"
                fill="#2E294E"
              />
            </svg>
          </button>
          <div className="Remove_from">
            {/* <img src={evnetlog} className="img-firud event-img" /> */}
            <h1>View Event Logs</h1>
            {/* <p>
              Do you really want to remove Dish? This action cannot be undone
            </p> */}

            <div className="Registers_tabal">
              <div className="thali_table_section new-table ">
                <table className="table table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">
                        {" "}
                        <span className="thali_table">Date</span>
                      </th>
                      <th scope="col">
                        <span className="thali_table">descritiopn</span>
                      </th>
                      <th scope="col">
                        <span className="thali_table">
                          created by(full name)
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {EventType?.map((item, index) => {
                      const formattedDate = new Date(
                        item?.created_at
                      ).toLocaleDateString();

                      return (
                        <tr>
                          <th scope="row">
                            <ul className="thali_menu_list view_Registeruser">
                              <li className="Form_box">{formattedDate}</li>
                            </ul>
                          </th>
                          <th scope="row">
                            <ul className="thali_menu_list">
                              <li>{item.event_body}</li>
                            </ul>
                          </th>
                          <th scope="row">
                            <ul className="thali_menu_list">
                              <li>{item.full_name}</li>
                            </ul>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="Thali_page_btn_from btn-div">
              <button onClick={RemovecloseModal} className="btn thali_btn">
                Close
              </button>
              {/* <button  className="btn thali_btn">
                Remove
              </button> */}
            </div>
          </div>
        </div>
      </Modal>
      {Labeldata.length > 0 && (
        <>
          {" "}
          <div className="labal_table p-3">
            <div className="table-data" ref={printRef}>
              {Array.isArray(Labeldata) && Labeldata.length > 0 ? (
                Labeldata.map((item, index) => {
                  const date = new Date(item.schedule_day);
                  const datePart = date.toISOString().split("T")[0];

                  return (
                    <>
                      <ul class="labal-sheet" key={index}>
                        <li>
                          {" "}
                          {moment(datePart).format("MM/DD/YYYY")} -{" "}
                          <span className="black">
                            {item?.pickup_location_name}
                          </span>{" "}
                        </li>
                        <li>
                          <span className="green">
                            {item?.portion_type_name}
                          </span>{" "}
                          -{" "}
                          {item?.want_roti === 1 ? (
                            <span className="red"></span>
                          ) : item?.want_roti === 0 ? (
                            <span className="red">NO</span>
                          ) : (
                            <>-</>
                          )}{" "}
                        </li>
                        {item?.want_rice === 1 ? (
                          <li className="blue"></li>
                        ) : item?.want_rice === 0 &&
                          item?.want_rice === null ? (
                          <li className="blue">NO</li>
                        ) : (
                          <li></li>
                        )}
                        <li>
                          <span className="blue-high">{item?.full_name} </span>
                        </li>
                        <li>{item?.phone ? item?.phone : "NA"}</li>
                      </ul>
                      {/* <tr>
                    <th scope="row">
                      <ul className='thali_menu_list'>
                        <li>{datePart}</li>
                      </ul>
                    </th>

                    <th scope="row">
                      <ul className='thali_menu_list'>
                        <li></li>
                      </ul>
                    </th>
                    <th scope="row">
                      <ul className='thali_menu_list'>
                        <li>{item?.portion_type_name}</li>
                      </ul>
                    </th>
                    <th scope="row">
                      <ul className='thali_menu_list'>
                        <li>{item?.pickup_location_name}</li>
                      </ul>
                    </th>

                    <th scope="row">
                      <ul className='thali_menu_list'>
                        {item?.want_roti === 1 ? (
                          <li>YES</li>
                        ) : item?.want_roti === 0 ? (
                          <li>NO</li>
                        ) : (
                          <li>-</li>
                        )}
                      </ul>
                    </th>

                    <th scope="row">
                      <ul className='thali_menu_list'>
                        <li>{item?.phone}</li>
                      </ul>
                    </th>
                  </tr> */}
                    </>
                  );
                })
              ) : (
                <div>
                  <div colSpan="6">No data available</div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {/* Edit  Thali popup */}
      {editshowPopup && (
                <div
                  className="offcanvas calender_canvas offcanvas-end"
                  ref={popupRef}
                >

                  {Thaliviewdata.map((Thalidatalist) => (
                    <div key={Thalidatalist}>

                    </div>
                  ))}
                  <div className="offcanvas-header p-0">
                    <div className="title_div">
                      {" "}
                      <h5 className="title_heading" id="title_heading">
                        Edit Menu
                      </h5>
                      <span className="Line_div">|</span>
                      {savedraft == "1" && (<span className="Draft_thali">{savedraft == "1" ? "Draft" : ""}</span>)}
                      {selectedOption == "MIQAAT" && is_niyaaz != '1' && (<span className="Draft_MIQAAT">{selectedOption == "MIQAAT" ? "MIQAAT" : ""}</span>)}
                      {selectedOption == "MIQAAT" && is_niyaaz == "1" && (<span className="Draft_NIYAAZ">{selectedOption == "MIQAAT" && is_niyaaz == "1" ? "PRIVATE" : ""}</span>)}
                      {selectedOption == "FMBTHALI" && is_niyaaz == "1" && (<span className="Draft_NIYAAZ">{selectedOption == "FMBTHALI" && is_niyaaz == "1" ? "MIQAAT-THALI" : ""}</span>)}
                      {jamantype == "ORDERING" && (<span className="Draft_ORDERING">{jamantype == "ORDERING" ? "ORDERING" : ""}</span>)}

                      <span className="Date_list">
                        {dateName(selectedDate)}{" "}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setEditShowPopup(false)}
                    ></button>
                  </div>

                  <div className="offcanvas-body">
                    <div className="Calnder_tab">
                      <h5 className="Calnder_tab_heding">Niyaz Type</h5>
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li
                          className={
                            selectedOption === "FMBTHALI" && is_niyaaz != "1"
                              ? "nav-item tab1"
                              : "nav-item"
                          }
                          role="presentation"
                        >
                          <div class="form-check">
                            <input
                              class="form-check-input active"
                              defaultChecked
                              id="home-tab"
                              type="radio"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              name="flexRadioDefault"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                              value="FMBTHALI"
                              checked={selectedOption === "FMBTHALI" && is_niyaaz != "1"}
                              onChange={handleRadioChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault"
                            >
                              FMB Thali
                            </label>
                          </div>
                        </li>
                        <li
                          className={
                            selectedOption === "FMBTHALI" && is_niyaaz == "1"
                              ? "nav-item tab1"
                              : "nav-item"
                          }
                          role="presentation"
                        >
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              role="tab"
                              aria-controls="home"
                              aria-selected="false"
                              name="flexRadioDefault "
                              value="FMBTHALI"
                              checked={selectedOption === "FMBTHALI" && is_niyaaz == "1"}
                              onChange={handleRadioNiyaazChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault"
                            >
                              Miqaat Thali
                            </label>
                          </div>
                        </li>
                        <li
                          className={
                            selectedOption === "MIQAAT" && is_niyaaz != "1"
                              ? "nav-item tab1"
                              : "nav-item"
                          }
                          role="presentation"
                        >
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              role="tab"
                              aria-controls="home"
                              aria-selected="false"
                              name="flexRadioDefault "
                              value="MIQAAT"
                              checked={selectedOption === "MIQAAT" && is_niyaaz != "1"}
                              onChange={handleRadioChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault"
                            >
                              Miqaat
                            </label>
                          </div>
                        </li>
                        <li
                          className={
                            selectedOption === "MIQAAT" && is_niyaaz == "1"
                              ? "nav-item tab1"
                              : "nav-item"
                          }
                          role="presentation"
                        >
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              id="profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#home"
                              role="tab"
                              aria-controls="home"
                              aria-selected="false"
                              name="flexRadioDefault "
                              value="MIQAAT"
                              checked={selectedOption === "MIQAAT" && is_niyaaz == "1"}
                              onChange={handleRadioNiyaazChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault"
                            >
                              Private
                            </label>
                          </div>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="home"
                          role="tabpanel"
                          aria-labelledby="home-tab"
                        >
                          <div className="Fmb_thali_calender">
                            <h5 className="Calnder_tab_heding">Jaman type</h5>
                            <ul
                              className="nav nav-tabs"
                              id="myTab2"
                              role="tablist"
                            >
                              <li
                                className={
                                  isDivVisible ? "nav-item" : "nav-item tab1"
                                }
                                role="presentation"
                              >
                                <div class="form-check">
                                  <input
                                    class="form-check-input active"
                                    type="radio"


                                    checked={!isDivVisible}
                                    name="Cooking"
                                    value="COOKING"
                                    onChange={handleTabClick}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="cooking-tab"
                                  >
                                    Cooking In-House
                                  </label>
                                </div>
                              </li>
                              <li
                                className={
                                  !isDivVisible ? "nav-item" : "nav-item tab1"
                                }
                                role="presentation"
                              >
                                <div class="form-check">
                                  <input

                                    class="form-check-input"
                                    type="radio"
                                    checked={isDivVisible}
                                    name="Cooking"
                                    value="ORDERING"
                                    onChange={handleTabClick}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="Ordering-tab"
                                  >
                                    Ordering
                                  </label>
                                </div>
                              </li>
                            </ul>

                            <div className="Menu_class">
                              {/* Order By */}
                              {isDivVisible ? (
                                <>
                                  <div className="row Fateha_div">
                                    <h1 className="Caterer_heading">
                                      Order from (Vendor)
                                    </h1>
                                    <div className="col-md-12">

                                      {/* <Select
                                          options={orders}
                                          className="dropdown "
                                          placeholder="Select"
                                          hideSelectedOptions={true}
                                          styles={colourStyles}
                                          value={order}
                                          onChange={(e) => setOrder(e)}
                                        /> */}

                                      <div className="Form_box">
                                        {/* <label
                                            for="inputCaterer"
                                            className="form-label"
                                          >
                                            Order from (Vendor)
                                          </label> */}
                                        <input
                                          type="text"
                                          placeholder="Enter Order from (Vendor) "
                                          className="form-control"
                                          id="Fateha"
                                          onChange={(e) =>
                                            setOrder(e.target.value)
                                          }
                                          value={order}
                                        />
                                      </div>

                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row">
                                    <h1 className="Caterer_heading">Caterer  New</h1>
                                    <div className="col-md-6">
                                      <div className="Form_box">
                                        <label
                                          for="inputCaterer"
                                          className="form-label"
                                        >
                                          Caterer1
                                        </label>
                                        <Select
                                          options={getCatererlistdata}
                                          className="dropdown "
                                          placeholder="Select"
                                          styles={colourStyles}
                                          isClearable={true}
                                          hideSelectedOptions={true}
                                          onChange={(e) => setCatererfirst(e)}
                                          value={getCatererlistdata.find((option) => option.value === catererfist)}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="Form_box">
                                        <label
                                          for="inputCaterer"
                                          className="form-label"
                                        >
                                          Caterer2
                                        </label>
                                        <Select
                                          options={getCatererlistdata}
                                          className="dropdown "
                                          placeholder="Select"
                                          isClearable={true}
                                          hideSelectedOptions={true}
                                          styles={colourStyles}
                                          onChange={(e) => setCaterersecond(e)}
                                          value={getCatererlistdata.find((option) => option.value === caterersecond)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            {/* roti_div */}

                            <div className="roti_div">
                              <div className="Roti_list">
                                <h5>Dishes</h5>
                                <button onClick={AddDishModal} className="btn">
                                  Add Dish
                                </button>
                              </div>
                              {/* {Array.from({ length: duplicateCount }, (_, index) => ( */}
                              {disheditdata.map((item, index) => (
                                <div key={index}>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="Form_box">
                                        <label
                                          for="inputCaterer"
                                          className="form-label"
                                        >
                                          {item.dish_type ? item.dish_type : item?.optiondata?.value}
                                          {/* {   DishTypeName(item.optiondata.label)} */}
                                          {/* {console.log('dddaaaaa',item.dishid.label)} */}
                                        </label>




                                        <Select
                                          options={dishviewlistdata}
                                          className="dropdown"
                                          placeholder="Select Dish Name"
                                          id="inputState"
                                          hideSelectedOptions={false}
                                          isClearable={false}
                                          styles={colourStyles}
                                          defaultValue={cleartype}
                                          onChange={(e) =>
                                            handleDishIdChange2(index, e)
                                          }
                                          onMenuOpen={(e) =>
                                            DishTypeName(item.dish_type ? item.dish_type : item.optiondata.value, niyaazdish)
                                          }
                                          
                                          value={item?.dish_name ? { label: item?.dish_name, value: item?.dish_name } : { label: item?.dishid?.label, value: item?.dishid?.value }}
                                        />

                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="Form_box">
                                        <label
                                          for="inputCaterer"
                                          className="form-label"
                                        >
                                          <br />
                                        </label>
                                        <div className="roti_full d-none">
                                          <div
                                            className={
                                              item.quantity === "HALF"
                                                ? "form-check tab1"
                                                : "form-check"
                                            }
                                          >
                                            <input
                                              class="form-check-input active"
                                              type="radio"
                                              defaultChecked
                                              value="HALF"
                                              checked={
                                                item.quantity === "HALF" ? item.quantity === "HALF" : item.selecteddish === "HALF"
                                              }
                                              onChange={(e) =>
                                                handleRadioButtonChange2(
                                                  e,
                                                  index
                                                )
                                              }
                                            />
                                            <label
                                              class="form-check-label"
                                              for="Half"
                                            >
                                              Half
                                            </label>
                                          </div>
                                          <div
                                            className={
                                              item.quantity === "FULL"
                                                ? "form-check tab1"
                                                : "form-check"
                                            }
                                          >
                                            <input
                                              class="form-check-input active"
                                              type="radio"
                                              defaultChecked
                                              value="FULL"
                                              checked={
                                                item.quantity === "FULL" ? item.quantity === "FULL" : item.selecteddish === "FULL"
                                              }
                                              onChange={(e) =>
                                                handleRadioButtonChange2(
                                                  e,
                                                  index
                                                )
                                              }
                                            />
                                            <label
                                              class="form-check-label"
                                              for="Half"
                                            >
                                              Full
                                            </label>
                                          </div>
                                        </div>
                                        <div className="roti_full">
                                          <div class="Delect_roti">
                                            <img
                                                onClick={() => handleEditRemove(index)}
                                              src={remove}
                                              className="img-firud"
                                            />
                                          </div>
                                          <button
                                          onClick={() => handleNavigateDishPage(item?.dish_name ? item?.dish_name : item?.dishid?.label, selectedOption)}
                                          className="Inv_Cal_Count_btn"
                                          >
                                          <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="17.5"
                                              height="17.5"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                          >
                                              <path
                                              d="M2.08327 13.2333C1.72771 13.2333 1.41938 13.1028 1.15827 12.8417C0.897157 12.5806 0.766602 12.2722 0.766602 11.9167V2.08333C0.766602 1.71667 0.897157 1.40278 1.15827 1.14167C1.41938 0.880556 1.72771 0.75 2.08327 0.75H6.64994V2.08333H2.08327V11.9167H11.9166V7.35H13.2499V11.9167C13.2499 12.2722 13.1194 12.5806 12.8583 12.8417C12.5972 13.1028 12.2833 13.2333 11.9166 13.2333H2.08327ZM5.58327 9.35L4.6666 8.41667L10.9999 2.08333H7.64994V0.75H13.2499V6.35H11.9166V3.01667L5.58327 9.35Z"
                                              fill="#957137"
                                              />
                                          </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="row">
                            {selectedOption === "MIQAAT"? <>   
                          </> :
                            <div className="col-md-6">
                            <div className="Form_box">
                              <label for="Standard" className="form-label">
                                Rice (Allow Skip)
                              </label>
                              <ul className="thali_menu_list">
                                <div>
                                  <div className="Register_user_input">
                                    <div className="onoffswitch">
                                      <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox " id="myonoffswitch" checked={allowSkipRice === "1"}
                                        onChange={handleAllowSkipRiceToggleChange} />
                                      <label className="onoffswitch-label" for="myonoffswitch">
                                        <span className="onoffswitch-inner"></span>
                                        <span className="onoffswitch-switch"></span>
                                      </label>
                                    </div>

                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                          }
                          {selectedOption === "MIQAAT" ? <>   
                          </> :
                          <div className="col-md-6">
                            <div className="Form_box">
                              <label for="Standard" className="form-label">
                                Roti (Allow Skip)
                              </label>
                              <ul className="thali_menu_list">
                                <div>
                                  <div className="Register_user_input">
                                    <div className="onoffswitch">
                                      <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="rice_check"
                                        checked={allowSkipRoti === "1"}
                                        onChange={handleAllowSkipRotiToggleChange} />
                                      <label className="onoffswitch-label" for="rice_check">
                                        <span className="onoffswitch-inner"></span>
                                        <span className="onoffswitch-switch"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                          }
                          </div>

                            <div className="Fateha_div">
                              <div className="row">
                                <h1 className="Caterer_heading del_all">Fateha <div class="Delect_roti">Clear: <img
                                  onClick={Resetfild}
                                  src={remove}
                                  className="img-firud"
                                />
                                </div></h1>
                                <div className="col-md-6">
                                  <div className="Form_box">
                                    <label
                                      for="inputCaterer" className="form-label"
                                    >
                                      Dish Name
                                    </label>
                                    <Select
                                      options={getnewlistdata}
                                      className="dropdown"
                                      placeholder="Select Fateha Dish"
                                      id="inputrice"
                                      styles={colourStyles}
                                      defaultValue={cleartype}
                                      onChange={(e) => setDishTypeId(e)}
                                      value={getnewlistdata.find((option) => option.value === dishtypeid)}
                                      isClearable={true}
                                      hideSelectedOptions={true}
                                    />

                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="Form_box">
                                    <label
                                      for="inputCaterer"
                                      className="form-label"
                                    >
                                      Fateha Name
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Fateha Name"
                                      className="form-control"
                                      id="Fateha"
                                      onChange={(e) =>
                                        setFatehaName(e.target.value)
                                      }
                                      value={fatehaname}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="Fateha_div">
                              <div className="row Menu_class">
                                <h1 className="Caterer_heading">
                                  Additional Notes
                                </h1>
                                <div className="col-md-12">
                                  <div className="Form_box">
                                    <label
                                      for="inputCaterer"
                                      className="form-label"
                                    >
                                      Comments
                                    </label>
                                    <textarea
                                      placeholder="Add your comments here..."
                                      className="form-control"
                                      id="Testlist"
                                      rows="4"
                                      onChange={(e) =>
                                        setComment(e.target.value)
                                      }
                                      value={comment}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>

                        {/* Comments */}
                        <div
                          className="tab-pane fade"
                          id="profile"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                        >
                          <div className="row Menu_class">
                            <div className="col-md-12">
                              <div className="Form_box">
                                <label
                                  for="inputCaterer"
                                  className="form-label"
                                >
                                  Comments
                                </label>
                                <textarea
                                  placeholder="Add your comments here..."
                                  className="form-control"
                                  id="Testlist"
                                  rows="8"
                                  onChange={(e) => setNote(e.target.value)}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                        {selectedOption === "MIQAAT"? <>

                        </> : <><div className="Fateha_div">
                          <div className="row Menu_class">
                            <h1 className="Caterer_heading">
                              Rsvp Date
                            </h1>
                            <div className="col-md-6">
                              <div className="Form_box">
                                <label
                                  for="inputCaterer"
                                  className="form-label"
                                >
                                  Rsvp End Date
                                </label>
                                <DatePicker value={alldatelist} onChange={handleDateChange} />
                                {/* {alldatelist} */}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="Form_box">
                                <label
                                  for="inputCaterer"
                                  className="form-label"
                                >
                                  Rsvp End Time
                                </label>
                                {/* <p>{endtime}</p> */}
                                <Select
                                  options={timezone}
                                  className="dropdown"
                                  placeholder="Select End Time "
                                  styles={colourStyles}
                                  hideSelectedOptions={true}
                                  // value={endtime.label}
                                  value={timezone.find((option) => option.label === endtime)}
                                  onChange={(e) => setEndTime(e.label)}
                                />

                              </div>
                            </div>
                          </div>
                        </div></>}

                      </div>
                    </div>
                  </div>
                  <div className="Ingredients_title">
                  <button onClick={() => RemoveMenuModal()}
                    type="button" id="modal_delete_btn2" data-bs-dismiss="offcanvas"
                    aria-label="Close" >Remove Menu</button>
                    &nbsp; &nbsp; &nbsp;     
                    &nbsp; &nbsp; &nbsp;    
                    &nbsp; &nbsp; &nbsp;    
                    &nbsp; &nbsp; &nbsp;    
                    &nbsp; &nbsp; &nbsp;    
                    &nbsp; &nbsp; &nbsp;    
                    &nbsp; &nbsp; &nbsp;    
                    &nbsp; &nbsp; &nbsp;    
                    &nbsp; &nbsp; &nbsp;    
                    &nbsp; &nbsp; &nbsp;   
                    &nbsp; &nbsp; &nbsp;               
                    <div className="Save_btn">
                      <button type="button" onClick={() => setEditShowPopup(false)} >
                        Cancel
                      </button>
                      <button type="submit" onClick={(e) => { setSaveDraft('1'); handleUpdateSubmit2(e, "1") }}>
                        Save As Draft
                      </button>
                      <button type="submit" onClick={(e) => handleUpdateSubmit2(e, '0')} >
                        Publish
                      </button>
                      {/* <button type="submit" onClick={handleUpdateSubmit2} >
                        Publish
                      </button> */}
                    </div>
                  </div>
                </div>
              )}

                    {/* Add Dish popup  */}
      <Modal
        isOpen={addDish}
        onAfterOpen={RemoveOpenModal}
        onRequestClose={DishcloseModal}
        style={customStyles}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        {" "}
        <div className="popup_modal add_dish_Add">
          <div className="d-flex justify-content-between align-items-center">
            <div className="Popup_heding">
              <img src={dishlist} className="img-firud" />
              &nbsp; &nbsp; &nbsp;&nbsp;
              <div className="edit_div">
                <h1>Add Dish </h1>
              </div>
            </div>
            <button onClick={DishcloseModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M6.99967 8.19364L1.82142 13.3719C1.65149 13.5418 1.45257 13.6268 1.22467 13.6268C0.996772 13.6268 0.797855 13.5418 0.627922 13.3719C0.458005 13.202 0.373047 13.0031 0.373047 12.7752C0.373047 12.5473 0.458005 12.3483 0.627922 12.1784L5.8062 7.00016L0.627922 1.82191C0.458005 1.65198 0.373047 1.45306 0.373047 1.22516C0.373047 0.99726 0.458005 0.798344 0.627922 0.62841C0.797855 0.458494 0.996772 0.373535 1.22467 0.373535C1.45257 0.373535 1.65149 0.458494 1.82142 0.62841L6.99967 5.80668L12.1779 0.62841C12.3479 0.458494 12.5468 0.373535 12.7747 0.373535C13.0026 0.373535 13.2015 0.458494 13.3714 0.62841C13.5413 0.798344 13.6263 0.99726 13.6263 1.22516C13.6263 1.45306 13.5413 1.65198 13.3714 1.82191L8.19315 7.00016L13.3714 12.1784C13.5413 12.3483 13.6263 12.5473 13.6263 12.7752C13.6263 13.0031 13.5413 13.202 13.3714 13.3719C13.2015 13.5418 13.0026 13.6268 12.7747 13.6268C12.5468 13.6268 12.3479 13.5418 12.1779 13.3719L6.99967 8.19364Z"
                  fill="#2E294E"
                />
              </svg>
            </button>
          </div>
          <div className="edit_from">
            <form>
              <div className="form-group  Form_box">
                <label>Dish type</label>

                <Select
                  options={dishTypeListData}
                  className="dropdown"
                  placeholder="Select Dish Type"
                  id="inputState"
                  styles={colourStyles}
                  hideSelectedOptions={true}
                  value={optiondata}
                  defaultValue={Defultdishtype}
                  onChange={(e) => {
                    setoptiondata(e);
                    DishTypeName(e.label, niyaazdish);
                    setDishId('');
                  }}

                />
              </div>

              <div className="form-group Form_box">
                <label className="repetitioncount">Dish Name   <span> Repetition Count {repetitioncount ? repetitioncount : "0"}</span></label>

                <Select
                  //  key={DishTypeName}
                  options={dishviewlistdata}
                  className="dropdown"
                  placeholder="Select Dish Name"
                  id="inputState"
                  hideSelectedOptions={true}
                  styles={colourStyles}
                  value={dishid ? dishid : "no dish add "}
                  defaultValue={type}
                  // onChange={(e) => setDishId(e)}
                  onChange={(e) => {
                    setDishId(e);
                    // setRepetitionCount(e.repetitioncountlist);
                    DishNameType(e.label, niyaazdish);
                    console.log("repetitioncount", e);
                  }}

                />
              </div>

              <div className="fulltali d-none">
                <div class="form-check">
                  <input
                    class="form-check-input active"
                    type="radio"
                    defaultChecked
                    checked={selecteddish === "HALF"}
                    onChange={handleDishChange}
                    value="HALF"
                  />
                  <label class="form-check-label" for="Half">
                    Half
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input active"
                    type="radio"
                    defaultChecked
                    value="FULL"
                    checked={selecteddish === "FULL"}
                    onChange={handleDishChange}
                  />
                  <label class="form-check-label" for="Half">
                    Full
                  </label>
                </div>
              </div>
              <div className="Thali_page_btn_from">
                <button
                  type="button"
                  onClick={DishcloseModal}
                  className="btn thali_btn"
                >
                  Cancel
                </button>
                &nbsp; &nbsp; &nbsp;
                <button
                  button="button"
                  className="btn thali_btn"
                  onClick={handleDuplicate}
                >
                  Add Dish
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={RemoveMenuIsOpen}
        style={customStyles}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        <div className="popup_modal">
          <div className="Remove_from">
            <img src={remove} className="img-firud" />
            <h1>Remove Menu</h1>
            <h6>{selectedOption} - {selectedDate}</h6>
            <h1>&nbsp;</h1>
            <h6>
              Do you really want to Remove this Schedule Menu?
            </h6>
            <h6>
              This action cannot be undone!
            </h6>
            <h1>&nbsp;</h1>
            <div className="Thali_page_btn_from btn-div">
              <button onClick={RemoveMenuCloseModal} className="btn thali_btn">
                Cancel
              </button>
              <button onClick={() => RemoveMenuData()} className="btn thali_btn">
                Remove Menu
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* {zonedata.length > 0 && (<> <div className=' labal_table p-3'>
        <div ref={printRef}>
          <div>
            {Array.isArray(zonedata) && zonedata.length > 0 ? (
              zonedata.map((item, index) => {

                return (
                  <>
                    <h5>{item.location}</h5>
                    <table className="table table-bordered">
                      <thead className="thead-dark">
                        <tr>
                          
                          <th scope="col"><span className='thali_table'>Name</span></th>
                          <th scope="col"><span className='thali_table'>Portion</span></th>
                          <th scope="col"><span className='thali_table'>Zone</span></th>
                          <th scope="col"><span className='thali_table'>Phone</span></th>
                          <th scope="col"><span className='thali_table'>Rice</span></th>
                          <th scope="col"><span className='thali_table'>Roti</span></th>
                          <th scope="col"><span className='thali_table'>Comments</span></th>
                        </tr>
                      </thead>
                      <tbody>
                        {item?.users?.map((itemall, index) => {
                          return (
                            <tr key={index}>
                       

                              <th scope="row">
                                <ul className='thali_menu_list'>
                                  <li>{itemall?.full_name}</li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className='thali_menu_list'>
                                  <li>{itemall?.portion_type_name}</li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className='thali_menu_list'>
                                  <li>{itemall?.pickup_location_name}</li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className='thali_menu_list'>
                                  <li>{itemall?.phone}</li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className='thali_menu_list'>
                                  {itemall?.want_rice === 1 ? (
                                    <li>YES</li>
                                  ) : itemall?.want_rice === 0 ? (
                                    <li>NO</li>
                                  ) : (
                                    <li>-</li>
                                  )}
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className='thali_menu_list'>
                                  {itemall?.want_roti === 1 ? (
                                    <li>YES</li>
                                  ) : itemall?.want_roti === 0 ? (
                                    <li>NO</li>
                                  ) : (
                                    <li>-</li>
                                  )}
                                </ul>
                              </th>

                            </tr>
                          )
                        }

                        )}

                      </tbody>
                    </table>

                    <div className='box'>
                      <ul class="list-group list-group-horizontal">
                        <li class="list-group-item tital">1-2 People</li>
                        <li class="list-group-item">{item?.counts.count_1_2_people}</li>
                      </ul>
                      <ul class="list-group list-group-horizontal-sm">
                        <li class="list-group-item tital">3-4 People</li>
                        <li class="list-group-item">{item?.counts.count_3_4_people}</li>
                      </ul>
                      <ul class="list-group list-group-horizontal-sm">
                        <li class="list-group-item tital"> X Small</li>
                        <li class="list-group-item">{item?.counts.count_x_people}</li>
                      </ul>
                      <ul class="list-group list-group-horizontal-sm">
                        <li class="list-group-item tital">with NO Rice</li>
                        <li class="list-group-item">{item?.counts.count_5_6_people}</li>
                      </ul>
                      <ul class="list-group list-group-horizontal-sm">
                        <li class="list-group-item tital">5- People</li>
                        <li class="list-group-item">{item?.counts.count_5_6_people}</li>
                      </ul>

                    </div>
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan="6">No data available</td>
              </tr>
            )}
          </div>
        </div>
      </div></>)} */}

      {/* {ingredientpdf.length > 0 && (<>
        <div className='labal_table p-3'>
          <table className="table table-bordered" ref={printRef}>
            <thead className="thead-dark">
              <tr>
                <th scope="col"><span className='thali_table'>Ingredient  Name</span></th>
                <th scope="col"><span className='thali_table'>PKG Type</span></th>
                <th scope="col"><span className='thali_table'>Zone</span></th>
                <th scope="col"><span className='thali_table'>PKG Size</span></th>
                <th scope="col"><span className='thali_table'>Vendor</span></th>

                <th scope="col"><span className='thali_table'>Menu Qty/Uni</span></th>
                <th scope="col"><span className='thali_table'>Total Quantity</span></th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(ingredientpdf) && ingredientpdf.length > 0 ? (
                ingredientpdf.map((item, index) => {
                  const date = new Date(item.schedule_day);
                  const datePart = date.toISOString().split('T')[0];

                  return (
                    <tr key={item.id}>
                      <th scope="row">
                        <ul className='thali_menu_list'>
                          <li>{item.ingredient_name}</li>
                        </ul>
                      </th>

                      <th scope="row">
                        <ul className='thali_menu_list'>
                          <li> {item.package_type ? item.package_type : '-'}</li>
                        </ul>
                      </th>
                      <th scope="row">
                        <ul className="thali_menu_list">
                          <li> {item.package_size ? item.package_size : "-"}</li>
                        </ul>
                      </th>
                      <th scope="row">
                        <ul className="thali_menu_list">
                          <li> {item.vendor_name1 ? item.vendor_name1 : '-'}</li>
                        </ul>
                      </th>


                      <th scope="row">
                        <ul className="thali_menu_list">
                          <li> {item.menu_quantity ? item.menu_quantity : '-'}</li>
                        </ul>
                      </th>

                      <th scope="row">
                        <ul className="thali_menu_list">
                          <li> {item.total_quantity ? item.total_quantity : ''}</li>
                        </ul>
                      </th>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div></>)} */}
    </>
  );
}
